import React, { Component } from 'react';
import { GoogleApiWrapper } from 'google-maps-react';
import CurrentLocation from './Map';
import Geocode from 'react-geocode';
import AppBar from './MenuBar';
import DraggableDialog from './DraggableDialog';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import FormDialog from './Dialog';
import './style.css';
import './Alert';
import AlertDialog from './Alert';
import Marker from './Marker';
import RegisterDocument from './RegisterDocument';
import getNearestStation from '../utils/GetStation';
// import getStationWithES from '../utils/GetStationWithES';

Geocode.setApiKey(process.env.REACT_APP_API_KEY);
Geocode.setLanguage('ja');
// set response region. Its optional.
Geocode.setRegion('jp');
const useStyles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cover: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    maxWidth: '380px',
    height: '150px',
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  infoWindowMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  contextmenuText: {
    fontSize: '11pt',
  }
});

const btn1Name = '売上予測';
const btn2Name = '面積測定';
const btn3Name = '間口測定';
const btn4Name = '最寄駅確認';
const btn5Name = '資料登録';
const btn6Name = 'ピン留め削除';
let buttonBar = [
  { id: 'polygon', name: '面積測定範囲の指定' },
  { id: 'polyline', name: '間口測定直線の指定' },
];

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false, //Hides or the shows the infoWindow
      activeMarker: {}, //Shows the active marker upon click
      selectedPlace: {}, //Shows the infoWindow to the selected place upon a marker
      placeName: '',
      markers: [
        // {
        //   pid: '1',
        //   title: '東京皇居',
        //   name: '東京皇居',
        //   position: { lat: 35.686086, lng: 139.752161 }
        // }
      ],
      sel_lat: '',
      sel_lng: '',
      showingPdDialog: false,
      showingAlDialog: false,
      showingAppbar: false,
      showingPlaceInfoEditor: false,
      imgFiles: [],
      memo: '',
      modelList: [],
      calc: false,
      area: {},
      frontage: {},
      drawingManager: null,
      selectedShape: null,
      shap: [],
      map: null,
      savedPhotoNum: 0,
      nearestStation: null,
      calcToggole: false,
      nearShopMarkers: [],
      circles: [],
      nearStationMarkers: [],
      showingRegisterDocument: false,
      register_images: [['','',''],['','',''],['','',''],['','',''],['','',''],['','',''],['','',''],['','',''],['','',''],['','','']],
      current_marker_place_name: '',
      register_memo: '',
    };
  }

  componentDidMount() {
    const { google } = this.props;
    const { map } = this.state;
    document.body.addEventListener('click',function (e){
      if(document.getElementById('contextmenu')!= null){
        //メニューをnoneで非表示にさせる
        document.getElementById('contextmenu').style.display="none";
      }
    });
    document.addEventListener('keydown', function (e){
      //メニューをnoneで非表示にさせる
      if (e.key === "Escape") {
        if(document.getElementById('contextmenu')!= null){
          document.getElementById('contextmenu').style.display="none";
        }
      }
    });

    // 面積・間口測定
    let drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER,
        drawingModes: [
          'polygon',
          'polyline',
        ],
      },
      polygonOptions: {
        fillColor: '#F06F6F',
        strokeColor: '#BE39C5',
      },
      polylineOptions: {
        strokeColor: 'orange',
      },
    });
    drawingManager.setMap(this.state.map);
    this.setState({
      drawingManager: drawingManager,
    });
    google.maps.event.addListener(
      drawingManager,
      'drawingmode_changed',
      (e) => {
        console.log("drawing mode changed:" + drawingManager.getDrawingMode());
      }
    );
    google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      (e) => {
        var newShape = e.overlay;
        newShape.type = e.type;
        var path = newShape.getPath();
        const placeName = this.state.selectedPlace.name.split(' ')[1];
        switch (e.type) {
          case google.maps.drawing.OverlayType.POLYGON:
            var area = google.maps.geometry.spherical.computeArea(path);
            var tsubo = area / 3.31;
            document.getElementById('area').innerHTML = btn2Name;
            this.setState({
              area: {...this.state.area, [placeName]: tsubo.toFixed(1)},
              calcToggole: false,
            });
            break;
          case google.maps.drawing.OverlayType.POLYLINE:
            var distance = google.maps.geometry.spherical.computeLength(
              path
            );
            document.getElementById('frontage').innerHTML = btn3Name;
            this.setState({
              frontage: {...this.state.frontage, [placeName]: Math.ceil(distance)},
              calcToggole: false,
            });
            break;
        }
        drawingManager.setDrawingMode(null);
        this.setState((previousState) => ({
          shap: [...previousState.shap, newShape],
          selectedShape: newShape,
        }));
      }
    );
  }

  onMarkerClick = (props, marker, e) => {
    //売上予測での住所を取得する。
    let current_marker_unique = '';
    {marker.title !== undefined ?
      current_marker_unique =  marker.title.split(' ')[1] : current_marker_unique = marker.title}
    localStorage.setItem('currentMarkerName',  current_marker_unique);

     //マウスの位置をstyleへ設定（左上の開始位置を指定）
     document.getElementById('contextmenu').style.left=e.domEvent.pageX+"px";
     document.getElementById('contextmenu').style.top=e.domEvent.pageY+"px";
     //メニューをblockで表示させる
     document.getElementById('contextmenu').style.display="block";

    this.setState({
      selectedPlace: props,
      placeName: props.name.split(' ')[1],
      activeMarker: marker,
      sel_lat: marker.position.lat(),
      sel_lng: marker.position.lng(),
      showingInfoWindow: true,
      showingAlDialog: false,
      imgFiles: [],
      savedPhotoNum: 0,
      memo: '',
      current_marker_place_name: current_marker_unique,
    });

    const pid = marker.pid;
    //this.getPlacePhotos(pid);
  };

  getPlacePhotos = (pid) => {
    const cur_user = JSON.parse(localStorage.getItem('user'));
    const uid = cur_user.uid;
    axios
      .get(process.env.REACT_APP_AUTH_SERVER + 'places/' + uid + '/' + pid, {})
      .then((res) => {
        this.setState({
          memo: res.data.memo,
          savedPhotoNum: res.data.imgs.length,
        });
        res.data.imgs.forEach((f) => {
          if (this.state.imgFiles.find((el) => el.title === f) !== undefined) {
            console.log('%s exists!', f);
            return;
          }
          axios
            .get(process.env.REACT_APP_AUTH_SERVER + 'files/photos/' + f, {
              responseType: 'arraybuffer',
            })
            .then((res) => {
              const newPhoto = {
                img: URL.createObjectURL(new Blob([res.data])),
                title: f,
                cols: 0,
              };
              this.setState((previousState) => ({
                imgFiles: [...previousState.imgFiles, newPhoto].slice(0, 4),
              }));
            });
        });
      })
      .catch((error) => {
        console.log(error);
        switch (error.response?.status) {
          case 400:
          case 401:
            this.props.setAuthTokens(null);
            this.props.setSignedInUser(null);
        }
        return;
      });
  };
  /**
   * Styles an element as a button
   */
  buttonize = (btn) => {
    // Set CSS for the control border.
    btn.type = 'button';
    btn.setAttribute(
      'style',
      `
		background-color: tomato;
		cursor: pointer;
		padding: 0px 8px;
		margin: 5px;
		margin-left: -5px;
		border-radius: 5px;
		color:white;
		font-size: 2em;
		font-weight: bold;
        height:48px;
		border:none;
	`
    );

    btn.addEventListener('mouseover', () => {
      btn.style.background = 'red';
    });
    btn.addEventListener('mouseout', () => {
      btn.style.background = 'tomato';
    });
  };

  onMapClick = (props, map, coord) => {
    const { google } = props;
    if (this.state.calc) {
      if (this.state.drawingManager === null) {
        var drawingManager = new google.maps.drawing.DrawingManager({
          // drawingMode: google.maps.drawing.OverlayType.POLYGON,
          drawingControl: false,
          drawingControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
            drawingModes: [
              // "marker",
              // "circle",
              'polygon',
              'polyline',
              // "rectangle"
            ],
          },
          // markerOptions: {
          //   icon:
          //     "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
          // },
          polygonOptions: {
            fillColor: '#F06F6F',
            strokeColor: '#BE39C5',
          },
          polylineOptions: {
            strokeColor: 'orange',
          },
        });
        drawingManager.setMap(map);

        // custom drawingControl
        buttonBar.forEach((btnItem) => {
          const btn = document.createElement('button');
          btn.innerHTML = btnItem.name;
          btn.id = btnItem.id;
          // add styles
          this.buttonize(btn);

          btn.addEventListener(
            'click',
            (() => {
              // closure handles local toggle variables
              let toggled = false;
              let drawingMode =
                btnItem.id === 'polygon'
                  ? google.maps.drawing.OverlayType.POLYGON
                  : google.maps.drawing.OverlayType.POLYLINE;
              const originalHTML = btn.innerHTML;
              return (e) => {
                if (toggled) {
                  drawingManager.setDrawingMode(null);
                  e.target.innerHTML = originalHTML;
                } else {
                  drawingManager.setDrawingMode(drawingMode);
                  e.target.innerHTML = '中止';
                }
                toggled = !toggled;
              };
            })()
          );
          map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(btn);
        });
        this.setState({
          drawingManager: drawingManager,
        });
        google.maps.event.addListener(
          drawingManager,
          'drawingmode_changed',
          (e) => {
            // console.log("drawing mode changed:" + drawingManager.getDrawingMode());
          }
        );
        google.maps.event.addListener(
          drawingManager,
          'overlaycomplete',
          (e) => {
            var newShape = e.overlay;
            newShape.type = e.type;
            var path = newShape.getPath();
            // var pArr = path.getArray();
            const placeName = this.state.selectedPlace.name.split(' ')[1];
            switch (e.type) {
              case google.maps.drawing.OverlayType.POLYGON:
                // var lengs = pArr.map((p, i) => google.maps.geometry.spherical.computeDistanceBetween(p, pArr[(i + 1) % pArr.length]).toFixed(2));
                // var distance = Math.max(...lengs);
                var area = google.maps.geometry.spherical.computeArea(path);
                var tsubo = area / 3.31;
                document.getElementById(buttonBar[0].id).innerHTML =
                  buttonBar[0].name;
                this.setState({
                  area: {...this.state.area, [placeName]: tsubo.toFixed(1)},
                });
                break;
              case google.maps.drawing.OverlayType.POLYLINE:
                var distance = google.maps.geometry.spherical.computeLength(
                  path
                );
                this.setState({
                  frontage: {...this.state.frontage, [placeName]: Math.ceil(distance)},
                });
                document.getElementById(buttonBar[1].id).innerHTML =
                  buttonBar[1].name;
                break;
              default:
                // if (this.state.drawingManager !== null) {
                //   this.state.drawingManager.setMap(map);
                //   this.state.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
                // }
                break;
            }
            drawingManager.setDrawingMode(null);
            this.setState((previousState) => ({
              shap: [...previousState.shap, newShape],
              selectedShape: newShape,
            }));
          }
        );
      } else {
        this.setDrawingBarVisible(false);
        this.state.drawingManager.setMap(map);
      }
    } else if (coord !== undefined) {
      const latLng = coord.latLng;
      this.setMarkerWithLocation(latLng);
    }
  };

  setMarkerWithLocation = (coord) => {
    if (coord !== undefined) {
      const lat = coord.lat();
      const lng = coord.lng();
      Geocode.fromLatLng(lat, lng).then(
        // Get address from latidude & longitude.
        (response) => {
          const address = response.results[0].formatted_address.replace('日本、', '');
          const pid = response.results[0].place_id;
          const addressComp = response.results[0].address_components;
          const provinceIndex =
            addressComp !== undefined && addressComp.length > 3
              ? addressComp[addressComp.length - 1].long_name.indexOf(
                  '-'
                ) > -1
                ? addressComp.length - 3
                : addressComp.length - 2
              : 0;
          let province =
            provinceIndex !== 0 ? addressComp[provinceIndex].long_name : '';
          this.setMarkers(pid, address, address, { lat, lng }, province);
        },
        (error) => {
          console.error('locating failed:', error);
        }
      );
    }
  };

  onClose = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingAlDialog: true,
        // showingInfoWindow: false,
        // activeMarker: null,
        // showingPdDialog: false,
      });
    }
  };

  onCloseSB = (props) => {
    this.setState({
      showingPdDialog: false,
    });
  };

  onCloseAB = (props) => {
    this.setState({
      showingAppbar: false,
    });
  };

  onOpenAB = (props) => {
    this.setState({
      showingAppbar: true,
    });
  };

  onOpenRegisterDocument = () => {
    this.setState({
      showingRegisterDocument: true,
    });
    //資料登録押下時にその場の資料を取得する。
    const cur_user = JSON.parse(localStorage.getItem('user'));
    const uid = cur_user.uid;
    //初期化
    this.setState({
      register_images: [['','',''],['','',''],['','',''],['','',''],['','',''],['','',''],['','',''],['','',''],['','',''],['','','']],
      register_memo: '',
    });

    const current_MarkerName = localStorage.getItem('currentMarkerName');
    const userToken = localStorage.getItem('userAuthToken');
    const setAuthTokens = this.props.auth.setAuthTokens;
    const setSignedInUser = this.props.user.setSignedInUser;

    axios
    .get(process.env.REACT_APP_AUTH_SERVER + 'places/' + uid + '/' + current_MarkerName,
    {
      timeout: 4000,
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    })
    .then((res) => {
      this.setState({
        register_images: res.data.imgs,
        register_memo:   res.data.memo,
      });
    })
    .catch((e) => {
      switch (e.response?.status) {
        case 400:
        case 401:
          setAuthTokens(null);
          setSignedInUser(null);
      }
      if(e.code == 'ECONNABORTED'){
        //console.log('タイムアウトになりました。');
      }
    });
  };

  onCloseRegisterDocument = () => {
    this.setState({
      showingRegisterDocument: false,
    });
  };

  setMarkers = (pid, address, name, location, province) => {
    const newMark = {
      pid: pid,
      title: name,
      name: address,
      position: location,
      province: province,
    };
    this.setState((previousState) => ({
      markers: [...previousState.markers, newMark],
      sel_lat: location.lat,
      sel_lng: location.lng,
    }));
  };

  setDrawingBarVisible = (isHidden) => {
    buttonBar.forEach((btn) => {
      let dom = document.getElementById(btn.id);
      if (dom !== null) dom.hidden = isHidden;
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });

    if (!event.target.checked) {
      this.setDrawingBarVisible(true);
      this.setState({
        area: {},
        frontage: {},
        shap: [],
        selectedShape: null,
      });
      if (this.state.selectedShape) {
        this.state.selectedShape.setMap(null);
      }
      this.state.shap.map((shap) => shap.setMap(null));

      if (this.state.drawingManager !== null)
        this.state.drawingManager.setMap(null);
      document.getElementById('area').innerHTML = '';
      document.getElementById('frontage').innerHTML = '';
    }
  };

  calcArea = (e) => {
    const { google } = this.props;
    const { drawingManager, calcToggole, map } = this.state;
    let drawingMode = google.maps.drawing.OverlayType.POLYGON;
    drawingManager.setMap(map);
    if (calcToggole && drawingManager.getDrawingMode() === google.maps.drawing.OverlayType.POLYGON) {
      drawingManager.setDrawingMode(null);
      e.target.innerHTML = btn2Name;
      this.setState({
        calcToggole: false,
      });
    } else if (!calcToggole || drawingManager.getDrawingMode() === google.maps.drawing.OverlayType.POLYLINE) {
      drawingManager.setDrawingMode(drawingMode);
      e.target.innerHTML = '中止';
      document.getElementById('frontage').innerHTML = btn3Name;
      this.setState({
        calcToggole: true,
      });
    }
  }

  calcFrontage = (e) => {
    const { google } = this.props;
    const { drawingManager, calcToggole, map } = this.state;
    let drawingMode = google.maps.drawing.OverlayType.POLYLINE;
    drawingManager.setMap(map);
    if (calcToggole && drawingManager.getDrawingMode() === google.maps.drawing.OverlayType.POLYLINE) {
      drawingManager.setDrawingMode(null);
      e.target.innerHTML = btn3Name;
      this.setState({
        drawingManager: drawingManager,
        calcToggole: false,
      });
    } else if (!calcToggole || drawingManager.getDrawingMode() === google.maps.drawing.OverlayType.POLYGON) {
      drawingManager.setDrawingMode(drawingMode);
      e.target.innerHTML = '中止';
      document.getElementById('area').innerHTML = btn2Name;
      this.setState({
        drawingManager: drawingManager,
        calcToggole: true,
      });
    }
  }

  setNearestStation = (placeName, stationName, dis, province) => {
    this.setState({
      nearestStation: {'placeName': placeName, 'stationName': stationName, 'dis': dis, 'province': province}
    });
  }

  doGetNearestStation = (results) => {
    const { google } = this.props;
    const { map, selectedPlace } = this.state;
    let centerPos = new google.maps.LatLng(selectedPlace.position);
    let add =
      selectedPlace.name.indexOf('〒') > -1
        ? selectedPlace.name.split(' ')
        : selectedPlace.name.indexOf('、') > -1 ? selectedPlace.name.split('、') : [];
    if (add.length === 1) {
      return this.setNearestStation(
        '海や砂漠などのアドレスなしの場所',
        null,
        null,
        null
      );
    }
    let mainName =
      add.length > 0
        ? add.slice(1, add.length).reduce((total, cur) => (total += cur))
        : selectedPlace.name;
    let dis = google.maps.geometry.spherical.computeDistanceBetween(
      centerPos,
      results[0].geometry.location
    );
    let marker = [];

    this.setNearestStation(mainName, results[0].name, dis, selectedPlace.province);
    results.slice(0, 3).forEach((e, i) => {
      let d = google.maps.geometry.spherical.computeDistanceBetween(
        centerPos,
        e.geometry.location
      );
      marker[i] = new google.maps.Marker({
        position: e.geometry.location,
        map: map,
        label: {
          text: '(' + Math.ceil(d) + 'm)',
          color: 'green',
        },
        icon: {
          url: ' https://maps.google.com/mapfiles/arrow.png',
          labelOrigin: new google.maps.Point(15, -10),
        },
      });
    });
    const nearStationMarker = {markers: marker, pid: this.state.activeMarker.pid}
    this.setState((previousState) => ({
      nearStationMarkers: [...previousState.nearStationMarkers, nearStationMarker],
    }));
  };

  errGetNearestStation = (status) => {
    // const { google } = this.props;
    // const { map, selectedPlace } = this.state;
    // let add =
    //   selectedPlace.name.indexOf('〒') > -1
    //     ? selectedPlace.name.split(' ')
    //     : selectedPlace.name.indexOf('、') > -1 ? selectedPlace.name.split('、') : [];
    // if (add.length === 1) {
    //   return this.setNearestStation(
    //     '海や砂漠などのアドレスなしの場所',
    //     null,
    //     null,
    //     null
    //   );
    // }
    // let mainName =
    //   add.length > 0
    //     ? add.slice(1, add.length).reduce((total, cur) => (total += cur))
    //     : selectedPlace.name;
    // let centerPos = new google.maps.LatLng(selectedPlace.position);
    // let post = selectedPlace.name.split(' ')[0].split('〒')[1];
    // let marker = [];

    // console.log(post + 'の最寄駅を取得中(MILIZE-APIで)');
    // getStationWithES(
    //   selectedPlace.position,
    //   3,
    //   (stations) => {
    //     stations = stations.sort((a, b) => {
    //       let latA = a._source.lat;
    //       let lngA = a._source.lon;
    //       let latB = b._source.lat;
    //       let lngB = b._source.lon;
    //       let t1 = new google.maps.LatLng(latA, lngA);
    //       let d1 = google.maps.geometry.spherical.computeDistanceBetween(
    //         centerPos,
    //         t1
    //       );
    //       let t2 = new google.maps.LatLng(latB, lngB);
    //       let d2 = google.maps.geometry.spherical.computeDistanceBetween(
    //         centerPos,
    //         t2
    //       );
    //       return d1 - d2;
    //     });
    //     let t = new google.maps.LatLng(
    //       [stations[0]._source.lat],
    //       [stations[0]._source.lon]
    //     );
    //     let dis = google.maps.geometry.spherical.computeDistanceBetween(
    //       centerPos,
    //       t
    //     );
    //     this.setNearestStation(
    //       mainName,
    //       stations[0]._source.station_name,
    //       dis,
    //       selectedPlace.province
    //     );
    //     stations.slice(0, 3).forEach((e, i) => {
    //       let t = new google.maps.LatLng([e._source.lat], [e._source.lon]);
    //       let d = google.maps.geometry.spherical.computeDistanceBetween(
    //         centerPos,
    //         t
    //       );
    //       marker[i] = new google.maps.Marker({
    //         position: t,
    //         map: map,
    //         label: {
    //           text: '(' + Math.ceil(d) + 'm)',
    //           color: 'green',
    //         },
    //         icon: {
    //           url: ' https://maps.google.com/mapfiles/arrow.png',
    //           labelOrigin: new google.maps.Point(15, -10),
    //         },
    //       });
    //     });
    //     const nearStationMarker = {markers: marker, pid: this.state.activeMarker.pid}
    //     this.setState((previousState) => ({
    //       nearStationMarkers: [...previousState.nearStationMarkers, nearStationMarker],
    //     }));
    //   },
    //   (status) => {}
    // );
  };

  searchNearestStation = (e) => {
    const { google } = this.props;
    const { map, selectedPlace } = this.state;
    let pos = selectedPlace.position;
    let pyrmont = new google.maps.LatLng(pos.lat, pos.lng);
    getNearestStation(
      google,
      map,
      pyrmont,
      this.doGetNearestStation,
      this.errGetNearestStation
    );
  }

  handleCloseDialog = () => {
    this.setState({
      showingPlaceInfoEditor: false,
    });
  };

  initMap = (props, map) => {
    this.setState({
      map: map,
    });
    this.setMarkerWithLocation(map.center);
  };

  setCircle = (pos, radius, google, map) => {
    const cir = new google.maps.Circle({
      center: pos,
      radius: radius,
      strokeColor: '#E6F101',
      strokeOpacity: 0.8,
      fillColor: '#00000000',
      fillOpacity: 0.35,
      map: map,
    });
    const circle = {circle: cir, pid: this.state.activeMarker.pid};
    this.setState((previousState) => ({
      circles: [...previousState.circles, circle],
    }));
  };

  setNearShopMarker = (polygonPoints, google, map) => {
    let marker = [];
    polygonPoints.forEach((e, i) => {
      marker[i] = new google.maps.Marker({
        position: e,
        map: map,
        icon: {
          url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
        },
      });
    });
    const nearShopMarker = {markers: marker, pid: this.state.activeMarker.pid}
    this.setState((previousState) => ({
      nearShopMarkers: [...previousState.nearShopMarkers, nearShopMarker],
    }));
  };

  deleteMarker = () => {
    let newMarkers = this.state.markers.filter(
      (m) => m.pid !== this.state.activeMarker.pid
    );

    // 周辺店舗のマーカーと同心円を一緒に削除する
    let newNearShopMarkers = this.state.nearShopMarkers.filter(
      (m) => m.pid !== this.state.activeMarker.pid
    );
    let deleteNearShopMarkers = this.state.nearShopMarkers.filter(
      (m) => m.pid === this.state.activeMarker.pid
    );
    for (const deleteNearShopMarker of deleteNearShopMarkers) {
      deleteNearShopMarker['markers'].forEach(
        (m) => m.setVisible(false)
      );
    }
    let newCircles = this.state.circles.filter(
      (c) => c.pid !== this.state.activeMarker.pid
    );
    let deleteCircles = this.state.circles.filter(
      (c) => c.pid === this.state.activeMarker.pid
    );
    for (const deleteCircle of deleteCircles) {
      deleteCircle['circle'].setVisible(false);
    }
    let newNearStationMarkers = this.state.nearStationMarkers.filter(
      (m) => m.pid !== this.state.activeMarker.pid
    );
    let deleteNearStationMarkers = this.state.nearStationMarkers.filter(
      (m) => m.pid === this.state.activeMarker.pid
    );
    for (const deleteNearStationMarker of deleteNearStationMarkers) {
      deleteNearStationMarker['markers'].forEach(
        (m) => m.setVisible(false)
      );
    }

    this.setState({
      showingAlDialog: false,
      showingInfoWindow: false,
      showingPdDialog: false,
      markers: newMarkers,
      nearShopMarkers: newNearShopMarkers,
      circles: newCircles,
      nearStationMarkers: newNearStationMarkers,
    });
  };

  render() {
    const { classes, google } = this.props;
    const round = (number, digit) => Math.floor(number * Math.pow(10, digit)) / Math.pow(10, digit);
    return (
      <div id='mapContainer'>
        <AppBar
          onPlaceLoaded={this.setMarkers}
          google={google}
          map={this.state.map}
        />
        <CurrentLocation
          centerAroundCurrentLocation
          currentLocation={{ lat: this.state.sel_lat, lng: this.state.sel_lng }}
          click={this.onMapClick}
          ready={this.initMap}
          google={this.props.google}
        >
          {this.state.markers.map((marker, index) => (
            <Marker
              onContextmenu={this.onMarkerClick}
              key={index}
              pid={marker.pid}
              title={marker.title}
              name={marker.name}
              province={marker.province}
              position={marker.position}
            />
          ))}
          <div id='contextmenu'>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <button
                onClick={(e) => {
                  this.setState({
                    showingPdDialog: true,
                  });
                }}
                className={classes.contextmenuText}
              >
                {btn1Name}
              </button>
              <button id='area' onClick={this.calcArea} className={classes.contextmenuText}>{btn2Name}</button>
              <button id='frontage' onClick={this.calcFrontage} className={classes.contextmenuText}>{btn3Name}</button>
              <button onClick={this.searchNearestStation} className={classes.contextmenuText}>{btn4Name}</button>
              <button onClick={this.onOpenRegisterDocument} className={classes.contextmenuText}>{btn5Name}</button>
              <button onClick={this.deleteMarker} className={classes.contextmenuText}>{btn6Name}</button>
            </div>
          </div>

          <FormDialog
            selectedPlace={this.state.selectedPlace}
            placeName={this.state.placeName}
            open={this.state.showingPdDialog}
            onClose={this.onCloseSB}
            modelList={this.state.modelList}
            title='予測実行'
            google={this.props.google}
            map={this.props.map}
            area={this.state.area}
            frontage={this.state.frontage}
            photos={this.state.imgFiles}
            memo={this.state.memo}
            nearestStation={this.state.nearestStation}
            setNearShopMarker={this.setNearShopMarker}
            setCircle={this.setCircle}
          />
          <DraggableDialog
            place={this.state.activeMarker}
            visible={this.state.showingPlaceInfoEditor}
            title={btn2Name}
            memo={this.state.memo}
            photos={this.state.imgFiles}
            savedPhotoNum={this.state.savedPhotoNum}
            onUpdated={(e) => {
              this.handleCloseDialog();
              const pid = this.state.activeMarker.pid;
              this.getPlacePhotos(pid);
            }}
            onClose={this.handleCloseDialog}
          />
        </CurrentLocation>
        <AlertDialog
          open={this.state.showingAlDialog}
          title={'ピン削除の確認'}
          msg={'ピンを削除しますか？'}
          cancelBtnName={'いいえ'}
          okBtnName={'はい'}
          handleClose={(e) => {
            this.setState({
              showingAlDialog: false,
              showingInfoWindow: false,
              showingPdDialog: false,
            });
          }}
          handleOK={this.deleteMarker}
        />
        <RegisterDocument
          open={this.state.showingRegisterDocument}
          onClose={this.onCloseRegisterDocument}
          register_imgs={this.state.register_images}
          register_memo={this.state.register_memo}
        />
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_API_KEY,
  language: 'ja',
  sensor: true,
  libraries: ['places', 'geometry', 'drawing'],
})(withStyles(useStyles)(MapContainer));
