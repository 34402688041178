import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AuthContext } from './context/auth';
import { UserContext } from './context/user';
import Home from './pages/Home';
import Signup from './pages/Signup';
import Login from './pages/Login';
import PrivateRoute from './PrivateRoute';
import ResetPwd from './pages/ResetPwd';
import ForgetPwd from './pages/ForgetPwd';

function App(props) {
  const existingTokens = JSON.parse(localStorage.getItem('tokens'));
  const [authTokens, setAuthTokens] = useState(existingTokens);
  const [signedInUser, setSignedInUsers] = useState({});

  const setTokens = (data) => {
    setAuthTokens(data);
    if (data === null) {
      localStorage.removeItem('tokens');
      localStorage.removeItem('setupTime');
    } else {
      localStorage.setItem('tokens', JSON.stringify(data));
      setExpiration(2);
    }
  };

  const setExpiration = (hours) => {
    let now = new Date().getTime();
    let setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
      localStorage.setItem('setupTime', now);
    } else {
      if (now - setupTime > hours * 60 * 60 * 1000) {
        localStorage.clear();
        localStorage.setItem('setupTime', now);
      }
    }
  };

  const setUser = (data) => {
    setSignedInUsers(data);
    if (data === null) {
      localStorage.removeItem('user');
      localStorage.removeItem('setupTime');
    } else {
      localStorage.setItem('user', JSON.stringify(data));
      setExpiration(2);
    }
  };

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <UserContext.Provider value={{ signedInUser, setSignedInUser: setUser }}>
        <Router>
          <PrivateRoute exact path='/' component={Home} />
          <Route path='/login' component={Login} />
          <Route path='/signup' component={Signup} />
          <Route path='/reset-pwd' component={ResetPwd} />
          <Route path='/forget-pwd' component={ForgetPwd} />
        </Router>
      </UserContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
