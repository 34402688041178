import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Select,
  TextField,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import * as Const from './Const';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import PredContent from './PredContent';
import CircularProgress from './CircularProgressWrapper';
import { between } from '../utils/ComputeDistance';
import { getNearbySearch } from '../utils/GetNearbySearch';
import getNearestStation from '../utils/GetStation';
// import getStationWithES from '../utils/GetStationWithES';
import Alert from './ErrorAlert';
import { useAuth } from '../context/auth';
import { useUser } from '../context/user';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    // margin: theme.spacing(1),
    margin: 0,
    minWidth: 100,
  },
  chip: {
    margin: theme.spacing(1),
    maxWidth: 100,
  },
  btn: {
    margin: theme.spacing(1),
  },
  input: {
    width: 58,
  },
  required: {
    color: 'blue !important',
  },
  number: {
    "& ::-webkit-inner-spin-button": {
      '-webkit-appearance': 'none',
      margin: 0,
      '-moz-appearance': 'textfield',
    },
    "& ::-webkit-outer-spin-button": {
      '-webkit-appearance': 'none',
      margin: 0,
      '-moz-appearance': 'textfield',
    },
  },
  inputGroupTitle: {
    textAlign: 'center',
    height: '30px',
    padding: '0px',
    color: 'gray',
    borderBottom: 'solid 1px darkgrey',
  },
  th1: {
    fontSize: '14px',
    color: 'gray',
    background: 'lightgray',
    fontWeight: 'lighter',
    textDecoration: 'underline',
    border: 'solid 1px darkgrey',
  },
  th2: {
    border: 'none',
    height: '5px',
  },
  th3: {
    border: 'none',
    height: '15px',
  },
  shopInputDialogItemNameCell: {
    fontSize: '14px',
    padding: '3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    width: '40%',
    background: 'rgb(240, 238, 238)',
    height: '30px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  shopInputDialogItemValueCell: {
    fontSize: '14px',
    padding: '3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    height: '30px',
  },
  shopInputDialogAddressNameCell: {
    fontSize: '14px',
    padding: '3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    width: '20%',
    background: 'rgb(240, 238, 238)',
    height: '30px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  shopInputDialogAddressValueCell: {
    fontSize: '14px',
    padding: '3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    height: '30px',
  },
  subDialogPaper: {
    minWidth: '800px',
  },
  dialogPaper: {
    maxWidth: '600px',
  },
  subDialogButton: {
    minWidth: '100px',
    fontWeight: 'normal',
    height: '30px',
    padding: '3px 13px',
  },
  subDialogButtonString: {
    fontSize: '0.875rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
  },
  inputBox: {
    width: '30%',
    height: '22px',
    justifyContent: 'center',
    paddingLeft: '5px',
    borderRadius: '0',
  },
  inputNearStation: {
    width: '80px',
    height: '22px',
    justifyContent: 'center',
    paddingLeft: '5px',
    borderRadius: '0',
  },
  select: {
    height: '20px',
    left: '5px',
    borderRadius: '0',
    marginRight: '5px',
  },
  selectLabel: {
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  selectRoot: {
    paddingLeft: '5px',
    padding: '0 14px',
    fontSize: '14px',
  },
  unit: {
    display: 'inline',
    justifyContent: 'center',
    paddingLeft: '5px',
    fontSize: '15px'
  },
  shopInputFont: {
    fontSize: '16px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
  },
  dialogContentRoot: {
    padding: '8px 16px 24px',
  },
  dialogItemNameCell: {
    fontSize: '14px',
    padding: '2px 3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    width: '80px',
    background: 'rgb(240, 238, 238)',
    height: '30px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  dialogItemValueCell: {
    fontSize: '14px',
    padding: '2px 3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    width: '280px',
    height: '30px',
  },
  dialogItemNameSmallCell: {
    fontSize: '14px',
    padding: '2px 3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    width: '100px',
    background: 'rgb(240, 238, 238)',
    height: '30px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  dialogItemValueSmallCell: {
    fontSize: '14px',
    padding: '2px 3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    width: '120px',
    height: '30px',
  },
  readyColor: {
    color: 'blue',
    fontWeight: 'bold',
  },
  notReadyColor: {
    color: 'red',
    fontWeight: 'bold',
  },
  checkbox: {
    padding: '0 5px 0 10px',
  },
  formControlLabelString: {
    fontSize: '14px',
    color: 'gray',
  },
  closeButtonPadding: {
    padding: '8px 12px',
  },
  textField: {
    borderRadius: '0',
  },
  progressDialog: {
    height: '200px',
    width: '200px',
  },
  disabledString: {
    color: 'black',
  },
}));

export default function FormDialogModel(props) {
  const classes = useStyles();
  const {
    title,
    open,
    onClose,
    selectedPlace,
    placeName,
    google,
    map,
    area,
    frontage,
    photos,
    memo,
    nearestStation,
    setNearShopMarker,
    setCircle,
  } = props;
  const { authTokens, setAuthTokens } = useAuth();
  const { setSignedInUser } = useUser();
  const [showDialog, setShowDialog] = useState(false);
  const [isNearbyReady, setIsNearbyReady] = useState(false);
  const [isInputReady, setIsInputReady] = useState(false);
  const [state, setState] = useState({
    restaurant: true,
    supermarket: true,
    home_goods_store: true,
    department_store: false,
    drugstore: true,
  });
  const [predictionResult, setPredictionResult] = useState({});
  const [errMsg, setErrMsg] = useState('');
  const [errOpen, setErrOpen] = useState(false);
  const [tmpList, setTmpList] = useState(['']);
  const [progress, setProgress] = useState(false);
  const [finishPrediction, setFinishPrediction] = useState({});
  const [station, setStation] = useState({});
  const strShopInfo = Const.strShopInfo;
  const pullDownList = Const.pullDownList;
  const KeyShopInfo = Const.KeyShopInfo;
  const shopInputElement = Object.keys(Const.shopInputDefault)
  const [shopInputDefault, setShopInputDefault] = useState(Const.shopInputDefault);
  // 選択中の店舗
  const [shopInput, setShopInput] = useState(shopInputDefault);
  // ピンのついているすべての店舗
  const [shop, setShop] = useState({});
  const shopInputNameMapping = Const.shopInputNameMapping;
  const shopInputValueMapping = Const.shopInputValueMapping;
  const [nearShop, setNearShop] = useState({});

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  // 業態の仮の値を受け取る値に変更
  const [businessMapping, setBusinessMapping] = useState({});
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'demo') {
      if (query.get('business') !== null && query.get('business').split(',').length === 2) {
        let item = pullDownList['業態'];
        let queryItem = query.get('business').split(',');
        setBusinessMapping({[queryItem[0]]: item[1], [queryItem[1]]: item[2]});
        queryItem.unshift('');
        pullDownList['業態'] = queryItem;
      } else {
        let item = pullDownList['業態'];
        setBusinessMapping({'たまち屋': item[1], 'たまち亭': item[2]});
        pullDownList['業態'] = ['','たまち屋','たまち亭'];
      }
    }
  }, []);

  const handlePrediction = async () => {
    const shopInputData = createPostData();
    console.log(shopInputData);
    setErrMsg('');
    setProgress(true);
    try {
      const results = await axios.post(
        process.env.REACT_APP_AI_API + '/predict_loadside_simplified',
        shopInputData,
        {
          ContentType: 'application/json'
        }
      )
      setPredictionResult(prevState => ({...prevState, [shopInputData['address']]: results.data}));
      setFinishPrediction(prevState => ({...prevState, [shopInputData['address']]: true}));
      setProgress(false);
    } catch(error) {
      console.log(error.response);
      let msg;
      if (error.toString().indexOf('502') > 0 || error.toString().indexOf('503') > 0 || !error.response?.data?.error) {
        msg = 'システムエラーが発生しました。システム管理者に連絡してください。';
      } else {
        msg = error.response.data.error;
      }
      setErrMsg(msg);
      setErrOpen(true);
      setProgress(false);
    }
  };

  const handleCrtPDF = async () => {
    let shopData = createPostData(false);

    // 画面に表示されている業態に戻す
    if (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'demo') {
      const result = Object.keys(businessMapping).filter((key) => {
        return businessMapping[key] === shopData['gyotai'];
      });
      shopData['gyotai'] = result;
    }
    shopData = {
      ...shopData,
      predictions: Math.floor(predictionResult[selectedPlace.name.split(' ')[1]]?.predictions),
      male_population_15_5minutes_walk: predictionResult[selectedPlace.name.split(' ')[1]]?.return_terra_data.male_population_15_5minutes_walk,
      female_population_15_5minutes_walk: predictionResult[selectedPlace.name.split(' ')[1]]?.return_terra_data.female_population_15_5minutes_walk,
      total_population_15_5minutes_walk: predictionResult[selectedPlace.name.split(' ')[1]]?.return_terra_data.total_population_15_5minutes_walk,
      total_population_change_from_10_to_15_5minutes_walk: predictionResult[selectedPlace.name.split(' ')[1]]?.return_terra_data.total_population_change_from_10_to_15_5minutes_walk,
      total_daytime_population_15S_5minutes_walk: predictionResult[selectedPlace.name.split(' ')[1]]?.return_terra_data.total_daytime_population_15S_5minutes_walk,
      number_of_households_15_5minutes_walk: predictionResult[selectedPlace.name.split(' ')[1]]?.return_terra_data.number_of_households_15_5minutes_walk,
      number_of_single_family_households_15_5minutes_walk: predictionResult[selectedPlace.name.split(' ')[1]]?.return_terra_data.number_of_single_family_households_15_5minutes_walk,
      number_of_owner_households_15_5minutes_walk: predictionResult[selectedPlace.name.split(' ')[1]]?.return_terra_data.number_of_owner_households_15_5minutes_walk,
      number_of_households_apartment_house_15_5minutes_walk: predictionResult[selectedPlace.name.split(' ')[1]]?.return_terra_data.number_of_households_apartment_house_15_5minutes_walk,
      retail_total_annual_sales_14S_5minutes_walk: predictionResult[selectedPlace.name.split(' ')[1]]?.return_terra_data.retail_total_annual_sales_14S_5minutes_walk,
    }
    console.log(shopData);

    const cur_user = JSON.parse(localStorage.getItem('user'));
    const uid = cur_user.uid;
    try {
      const html = await axios.post(
        process.env.REACT_APP_AUTH_SERVER + 'pdf/create',
        {
          uid: uid,
          shopData: shopData,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens}`,
            ContentType: 'application/json',
          }
        }
      );
      document.getElementById('p1').innerHTML = html.data.p1.toString();
      document.getElementById('p2').innerHTML = html.data.p2.toString();
    } catch(error) {
      switch (error.response?.status) {
        case 400:
        case 401:
          setAuthTokens(null);
          setSignedInUser(null);
      }
      return;
    }

    document.getElementById('divtoPDF').hidden = false;
    const p1 = document.getElementById('p1');
    const p2 = document.getElementById('p2');
    const pdf = new jsPDF('p', 'pt', 'a4');
    html2canvas(p1, {
      useCORS: true,
      dpi: 72,
    }).then((canvas) => {
      let pageData = canvas.toDataURL('image/jpeg');
      let width = pdf.internal.pageSize.width;
      pdf.addImage(pageData, 'JPEG', 0, 20, width, 0);
      pdf.addPage();

      html2canvas(p2, {
        useCORS: true,
        dpi: 72,
      }).then((canvas2) => {
        pageData = canvas2.toDataURL('image/jpeg');
        width = pdf.internal.pageSize.width;
        pdf.addImage(pageData, 'JPEG', 0, 20, width, 0);
      });

      let today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      const hh = today.getHours();
      const min = today.getMinutes();
      const ss = today.getSeconds();

      today = yyyy + mm + dd + '_' + hh + min + ss;
      setTimeout(() => {
        // pdf.save('predictionResult' + today + '.pdf');
        window.open(pdf.output('bloburl', { filename: 'predictionResult' + today + '.pdf' }));
        document.getElementById('divtoPDF').hidden = true;
      }, 200);
    });
  };

  // 数値の入力チェック
  const checkNumber = (key, value, min, max, isFloat, maxLength) => {
    value = value.replace(',', '');
    if (isFloat) {
      let reg;
      if (key === '敷地面積') {
        reg = new RegExp(String.raw`(\d{1,5})(\.\d{0,1})?`);
      } else if (key === '間口' || key === '間口 ※車道のみ') {
        reg = new RegExp(String.raw`(\d{1,3})(\.\d{0,1})?`);
      } else {
        reg = new RegExp(String.raw`(\d{1,3})(\.\d{0,2})?`);
      }
      if (value === '') {
        value = ''
      } else if (typeof value !== `number` && value.match(reg) === null) {
        value = null
      } else if (min !== null && Number(value) < min) {
        value = null
      } else if (max !== null && Number(value) > max) {
        value = max
      } else {
        let n = 2
        value = value.match(reg)[0]
        // value = Math.floor(Number(value) * Math.pow(10, n)) / Math.pow(10, n)
      }
    } else {
      let reg = new RegExp(String.raw`\d{1,${maxLength}}`)
      if (value === '') {
        value = ''
      } else if (typeof value !== `number` && value.match(reg) === null) {
        value = null
      } else if (min !== null && Number(value) < min) {
        value = null
      } else if (max !== null && Number(value) > max) {
        value = max
      } else {
        value = value.match(reg)[0]
        value = Number(value).toFixed(0)
      }
    }
    return value === null ? null : String(value)
  }

  const processNumber = (key, val) => {
    let eKey = '充足度';
    let seki = shopInput['席数'] ? shopInput['席数'].replace(',', '') : '';
    let parking = shopInput['台数'] ? shopInput['台数'].replace(',', '') : '';
    let eVal = shopInput[eKey] ? Number(shopInput[eKey]) : '';
    if (key === '台数') {
      if (val === '' || seki === '' || seki === null || seki === '0') {
        // 駐車場充足度をNullする
        eVal = null
      } else {
        eVal = Number((Number(val) / Number(seki)) * 100).toFixed(1);
        // 充足度の上限は200%
        eVal = Math.min(eVal, 200);
      }

    } else if (key === "席数") {
      if (val === '' || val === '0' || parking === '' || parking === null) {
        eVal = null;
      } else {
        eVal = Number(Number(parking) / Number(val) * 100).toFixed(1);
        // 充足度の上限は200%
        eVal = Math.min(eVal, 200);
      }
    }

    // 1車線当たり交通量の計算
    const traffic_per_lane = '1車線当たり交通量';
    const traffic = shopInput['交通センサス合計'] ? shopInput['交通センサス合計'].replace(',', '') : '';
    const lanes = shopInput['トータル車線数'] ? shopInput['トータル車線数'].replace(',', '') : '';
    let traffic_per_lane_Val = shopInput[traffic_per_lane] ? shopInput[traffic_per_lane] : '';
    if (key === '交通センサス合計') {
      if (val === '' || lanes === '' || lanes === '0') {
        traffic_per_lane_Val = null
      } else {
        traffic_per_lane_Val = Math.round(Number(val) / Number(lanes)).toLocaleString();
      }
    } else if (key === 'トータル車線数') {
      if (val === '' || val === '0' || traffic === '') {
        traffic_per_lane_Val = null;
      } else {
        traffic_per_lane_Val = Math.round(Number(traffic) / Number(val)).toLocaleString();
      }
    }
    if (val !== '') {
      // val = Number(val).toLocaleString();
      let s = val.split('.');
      let ret = String(s[0]).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      if (s.length > 1) {
          ret += '.' + s[1];
      }
      val = ret;
    }
    setShopInput({ ...shopInput, [key]: val, [eKey]: eVal, [traffic_per_lane]: traffic_per_lane_Val })
  }

  const handleInputChange = (event, isFloat) => {
    let key = event.target.id;
    let val = event.target.value
    let maxLength = event.target.maxLength
    if (strShopInfo.indexOf(key) === -1) {
      if ((val = checkNumber(key, val, 0, null, isFloat, maxLength)) !== null) {
        processNumber(key, val)
      }
    } else {
      setShopInput({ ...shopInput, [key]: val })
    }
  }

  const handleInputKeyDown = (event, isFloat) => {
    if (isFloat) {
      if (event.key === 'e' || event.key === '-' || event.key === '+') {
        event.preventDefault()
      }
    } else {
      if (event.key === 'e' || event.key === '-' || event.key === '+' || event.key === '.') {
        event.preventDefault()
      }
    }
  }

  const setKobunruiList = (ooBunrui) => {
    let lst =
      ooBunrui === '(RS)ロードサイド'
        ? pullDownList['小分類'].slice(0, 4)
        : ooBunrui === '(BI)ビルイン'
        ? pullDownList['小分類'].slice(4, 5)
        : [];
    lst.unshift('');
    setTmpList(lst);
  };

  // 値から記号もしくは数値の部分のみを取得
  const changeSelectValue = (value) => {
    if (value in shopInputValueMapping) {
      return shopInputValueMapping[value];
    } else {
      return value;
    }
  };

  const handlePullDownChange = (event) => {
    const key = event.target.name;
    const v = event.target.value;
    if (key === '大分類') {
      setKobunruiList(v);
    }
    let val = strShopInfo.indexOf(key) === -1 && v !== '' ? Number(v) : v;
    setShopInput({ ...shopInput, [key]: val });
  };

  const handleErrDialogClose = () => {
    setErrMsg('');
    setErrOpen(false);
  };

  const createPostData = (isPredict=true) => {
    let shopInputData = { ...shop[placeName], ...nearShop[placeName] };
    for (const [key, value] of Object.entries(shopInputData)) {
      if (key in shopInputNameMapping) {
        if (key === '業態' && (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'demo')) {
          shopInputData[shopInputNameMapping[key]] = businessMapping[value];
        } else if (value === '') {
          shopInputData[shopInputNameMapping[key]] = null;
        } else if (key === '充足度' && value !== null) {
          shopInputData[shopInputNameMapping[key]] = value / 100;
        } else if (Object.keys(pullDownList).indexOf(key) > -1) {
          shopInputData[shopInputNameMapping[key]] = changeSelectValue(value);
        } else if (isPredict && shopInputElement.indexOf(key) > -1 && strShopInfo.indexOf(key) === -1 && typeof value === 'string') {
          // 物件入力画面の数値のみ
          shopInputData[shopInputNameMapping[key]] = value?.replace(',', '');
        } else {
          shopInputData[shopInputNameMapping[key]] = value;
        }
        // 項目名変更前(日本語の項目)の値を削除
        if (key !== shopInputNameMapping[key]) {
          delete shopInputData[key];
        }
      }
    }
    return shopInputData;
  };

  const getNearByInfo = async (location, shop_types) => {
    let foodCompetitor;
    let supermarketCompetitor;
    let homeGoodsStoreCompetitor;
    let drugstoreCompetitor;

    let polygonPoints = [];
    for (const shop_type of shop_types) {
      const shopList = await getNearbySearch(google, map, location, shop_type);
      if (shop_type === 'restaurant') {
        foodCompetitor = shopList.map((e) => {
          const pos = {lat: e.lat, lng: e.lng};
          const distance = between(location, pos);
          const competitor = {food_competitor: e.name, food_competitor_distance: distance};
          return competitor;
        });
        polygonPoints.push(...shopList.map(e => e.location));
      } else if (shop_type === 'supermarket') {
        supermarketCompetitor = shopList.map((e) => {
          const pos = {lat: e.lat, lng: e.lng};
          const distance = between(location, pos);
          const competitor = {supermaket_competitor: e.name, supermaket_competitor_distance: distance};
          return competitor;
        });
      } else if (shop_type === 'home_goods_store') {
        homeGoodsStoreCompetitor = shopList.map((e) => {
          const pos = {lat: e.lat, lng: e.lng};
          const distance = between(location, pos);
          const competitor = {homecenter_competitor: e.name, homecenter_competitor_distance: distance};
          return competitor;
        });
      } else if (shop_type === 'drugstore') {
        drugstoreCompetitor = shopList.map((e) => {
          const pos = {lat: e.lat, lng: e.lng};
          const distance = between(location, pos);
          const competitor = {drug_store_competitor: e.name, drug_store_competitor_distance: distance};
          return competitor;
        });
      }
    }
    setNearShop({
      ...nearShop,
      [placeName]: {
        food_competitor_list: foodCompetitor,
        supermaket_competitor_list: supermarketCompetitor,
        homecenter_competitor_list: homeGoodsStoreCompetitor,
        drug_store_competitor_list: drugstoreCompetitor
      }
    });
    setNearShopMarker(polygonPoints, google, map);
    setCircle(new google.maps.LatLng(location.lat, location.lng), 1500, google, map);
    setIsNearbyReady({...isNearbyReady, [placeName]: true});
    setProgress(false);
  };

  const doGetNearestStation = (results) => {
    let centerPos = new google.maps.LatLng(selectedPlace.position);
    // let add =
    //   selectedPlace.name.indexOf('〒') > -1
    //     ? selectedPlace.name.split(' ')
    //     : selectedPlace.name.indexOf('、') > -1 ? selectedPlace.name.split('、') : [];
    // if (add.length === 1) {
    //   return this.setNearestStation(
    //     '海や砂漠などのアドレスなしの場所',
    //     null,
    //     null,
    //     null
    //   );
    // }
    // let mainName =
    //   add.length > 0
    //     ? add.slice(1, add.length).reduce((total, cur) => (total += cur))
    //     : selectedPlace.name;
    let dis = google.maps.geometry.spherical.computeDistanceBetween(
      centerPos,
      results[0].geometry.location
    );
    setNearestStation({'stationName': results[0].name, 'dis': dis});
    setStation({...station, [placeName]: {'stationName': results[0].name, 'dis': dis}});
  };

  const errGetNearestStation = (status) => {
    // let centerPos = new google.maps.LatLng(selectedPlace.position);
    // let post = selectedPlace.name.split(' ')[0].split('〒')[1];

    // console.log(post + 'の最寄駅を取得中(MILIZE-APIで)');
    // getStationWithES(
    //   selectedPlace.position,
    //   3,
    //   (stations) => {
    //     stations = stations.sort((a, b) => {
    //       let latA = a._source.lat;
    //       let lngA = a._source.lon;
    //       let latB = b._source.lat;
    //       let lngB = b._source.lon;
    //       let t1 = new google.maps.LatLng(latA, lngA);
    //       let d1 = google.maps.geometry.spherical.computeDistanceBetween(
    //         centerPos,
    //         t1
    //       );
    //       let t2 = new google.maps.LatLng(latB, lngB);
    //       let d2 = google.maps.geometry.spherical.computeDistanceBetween(
    //         centerPos,
    //         t2
    //       );
    //       return d1 - d2;
    //     });
    //     let t = new google.maps.LatLng(
    //       [stations[0]._source.lat],
    //       [stations[0]._source.lon]
    //     );
    //     let dis = google.maps.geometry.spherical.computeDistanceBetween(
    //       centerPos,
    //       t
    //     );
    //     setNearestStation({'stationName': stations[0]._source.station_name, 'dis': dis});
    //     setStation({...station, [placeName]: {'stationName': stations[0]._source.station_name, 'dis': dis}});
    //   },
    //   (status) => {setNearestStation({'stationName': null, 'dis': null});}
    // );
    setNearestStation({'stationName': null, 'dis': null});
  };

  // MapContainerとは違い、マーカーは設置しない
  const searchNearestStation = () => {
    let pos = selectedPlace.position;
    let pyrmont = new google.maps.LatLng(pos.lat, pos.lng);
    getNearestStation(
      google,
      map,
      pyrmont,
      doGetNearestStation,
      errGetNearestStation
    );
  }

  const setNearestStation = (station) => {
    const stationName = station !== null && 'stationName' in station ? station.stationName : null;
    const dis = station !== null && 'dis' in station ? station.dis : null;
    let shopList = {
      ...shopInputDefault,
      ...shop[placeName],
      業態: pullDownList['業態'][1],
      住所: placeName,
      敷地面積: area[placeName] !== undefined ? Number(area[placeName]).toLocaleString()
              : placeName in shop && '敷地面積' in shop[placeName] && shop[placeName]['敷地面積'] ? shop[placeName]['敷地面積']
              : null,
      間口: frontage[placeName] !== undefined ? frontage[placeName].toLocaleString()
          : placeName in shop && '間口' in shop[placeName] && shop[placeName]['間口'] ? shop[placeName]['間口']
          : null,
      最寄駅: stationName,
      距離: dis !== null ? Math.round(dis).toLocaleString() : null,
    };
    setKobunruiList(shopList['大分類']);
    setShopInput(shopList);
    setShowDialog(true);

    // クリアボタン用の初期値保存
    let shopDefaultList = {
      ...shopInputDefault,
      業態: pullDownList['業態'][1],
      住所: placeName,
      敷地面積: area[placeName] !== undefined ? area[placeName].toLocaleString()
              : null,
      間口: frontage[placeName] !== undefined ? frontage[placeName].toLocaleString()
          : null,
      最寄駅: stationName,
      距離: dis !== null ? Math.round(dis).toLocaleString() : null,
    };
    setShopInputDefault(shopDefaultList);
  };

  const openSubDialog = () => {
    if (nearestStation === null && station[placeName] === undefined) {
      searchNearestStation();
    } else if (station[placeName] !== undefined) {
      setNearestStation(station[placeName]);
    } else {
      setNearestStation(nearestStation);
    }
  };

  const getShopTypes = () => {
    let shopTypes = [];
    for (const [key, value] of Object.entries(state)) {
      if (value) {
        shopTypes.push(key);
      }
    }
    return shopTypes;
  };
  const getNearShop = () => {
    setProgress(true);
    getNearByInfo(selectedPlace.position, getShopTypes());
  };

  const closeSubDialog = (event) => {
    setShowDialog(false);
  };

  const saveInput = (event) => {
    let input = {...shopInput};
    setShop({...shop, [shopInput['住所']]: input});
    setIsInputReady({...isInputReady, [shopInput['住所']]: true});
    setShowDialog(false);
  };

  const setDefault = (event) => {
    setShopInput(shopInputDefault);
    setIsInputReady({...isInputReady, [shopInput['住所']]: false});
    let lst = pullDownList['小分類'].slice(0, 4);
    lst.unshift('');
    setTmpList(lst);
  }

  const shopElement = (type, name, unit='', required=false, isFloat=false) => {
    if (name === '住所') {
      const elemNameClassName = required ? classes.shopInputDialogAddressNameCell + ' ' + classes.required : classes.shopInputDialogAddressNameCell;
      const elemValueClassName = classes.shopInputDialogAddressValueCell;
      return (
        <TableRow>
          <TableCell className={elemNameClassName}>
            {name}
          </TableCell>
          <TableCell className={elemValueClassName}>
            <TextField
              required={KeyShopInfo.indexOf(name) > -1}
              inputProps={{style: {fontSize: '14px', padding: '2px'}}}
              InputProps={{classes: {root: classes.textField}}}
              margin='none'
              fullWidth
              id={name}
              variant="outlined"
              value={shopInput[name]}
              onChange={(event) => handleInputChange(event, isFloat)}
              onBlur={(event) => handleInputChange(event, isFloat)}
              style={{width: '98%', height: '22px', justifyContent: 'center', paddingLeft: '5px'}}
            />
            <Typography
              variant='body1'
              component='div'
              className={classes.unit}
            >
              {unit}
            </Typography>
          </TableCell>
        </TableRow>
      )
    } else if (type === 'text') {
      const elemNameClassName = required ? classes.shopInputDialogItemNameCell + ' ' + classes.required : classes.shopInputDialogItemNameCell;
      const elemValueClassName = classes.shopInputDialogItemValueCell;
      return (
        <TableRow>
          <TableCell className={elemNameClassName}>
            {name}
          </TableCell>
          <TableCell className={elemValueClassName}>
            {name === '席数' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 3, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'tel'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputBox}
                id={name}
                value={shopInput[name] ? shopInput[name] : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '敷地面積' && (
              <TextField
                margin='none'
                inputProps={{style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'tel'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputBox}
                id={name}
                value={shopInput[name] ? shopInput[name] : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, true)}
                onBlur={(event) => handleInputChange(event, true)}
                onKeyDown={(event) => handleInputKeyDown(event, true)}
              />
            )}
            {name === '切下げ有接道数' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 1, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'tel'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputBox}
                id={name}
                value={shopInput[name] ? shopInput[name] : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '台数' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 5, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'tel'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputBox}
                id={name}
                value={shopInput[name] ? shopInput[name] : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '充足度' && (
              <TextField
                className={classes.inputBox}
                disabled
                inputProps={{style: { textAlign: 'right', fontSize: '14px', padding: '2px' }}}
                InputProps={{classes: {root: classes.textField, disabled: classes.disabledString}}}
                margin='none'
                fullWidth
                id={name}
                variant="outlined"
                value={shopInput[name] ? shopInput[name] : ''}
                onChange={(event) => handleInputChange(event, false)}
              />
            )}
            {name === '最寄駅' && (
              <TextField
                className={classes.inputNearStation}
                inputProps={{style: { fontSize: '14px', padding: '2px' }}}
                InputProps={{classes: {root: classes.textField}}}
                margin='none'
                fullWidth
                id={name}
                variant="outlined"
                value={shopInput[name] ? shopInput[name] : ''}
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                style={{width: '50%'}}
              />
            )}
            {name === '乗降客数' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 8, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'tel'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputNearStation}
                id={name}
                value={shopInput[name] ? shopInput[name] : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '距離' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 6, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'tel'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputNearStation}
                id={name}
                value={shopInput[name] ? shopInput[name] : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '間口' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 5, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'tel'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputBox}
                fullWidth
                id={name}
                variant="outlined"
                value={shopInput[name] ? shopInput[name] : ''}
                onChange={(e) => handleInputChange(e, true)}
                onBlur={(e) => handleInputChange(e, true)}
                onKeyDown={(event) => handleInputKeyDown(event, true)}
              />
            )}
            {name === '交通センサス合計' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 7, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'tel'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputBox}
                id={name}
                value={shopInput[name] ? shopInput[name] : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
                style={{width: '80px'}}
              />
            )}
            {name === 'トータル車線数' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 2, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'tel'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputBox}
                id={name}
                value={shopInput[name] ? shopInput[name] : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '店側車線数' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 2, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'tel'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputBox}
                id={name}
                value={shopInput[name] ? shopInput[name] : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '1車線当たり交通量' && (
              <TextField
                margin='none'
                disabled
                inputProps={{maxLength: 2, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'tel'}}
                InputProps={{classes: {root: classes.textField, disabled: classes.disabledString}}}
                className={classes.number + ' ' + classes.inputBox}
                id={name}
                value={shopInput[name] ? shopInput[name] : ''}
                fullWidth
                variant="outlined"
                onChange={(event) => handleInputChange(event, false)}
                onBlur={(event) => handleInputChange(event, false)}
                onKeyDown={(event) => handleInputKeyDown(event, false)}
              />
            )}
            {name === '間口 ※車道のみ' && (
              <TextField
                margin='none'
                inputProps={{maxLength: 5, pattern: "^[0-9_]+$", style: { textAlign: 'right', fontSize: '14px', padding: '2px' }, type: 'tel'}}
                InputProps={{classes: {root: classes.textField}}}
                className={classes.number + ' ' + classes.inputBox}
                fullWidth
                id={name}
                variant="outlined"
                value={shopInput[name] ? shopInput[name] : ''}
                onChange={(e) => handleInputChange(e, true)}
                onBlur={(e) => handleInputChange(e, true)}
                onKeyDown={(event) => handleInputKeyDown(event, true)}
              />
            )}
            <Typography
              variant='body1'
              component='div'
              className={classes.unit}
            >
              {unit}
            </Typography>
          </TableCell>
        </TableRow>
      )
    } else if (type === 'select') {
      const elemNameClassName = required ? classes.shopInputDialogItemNameCell + ' ' + classes.required : classes.shopInputDialogItemNameCell;
      const elemValueClassName = classes.shopInputDialogItemValueCell;
      let shopInputElementName;
      if (name === 'メイン道路種類' || name === 'サブ1道路種類') {
        shopInputElementName = '種類';
      } else {
        shopInputElementName = name;
      }
      return (
        <TableRow>
          <TableCell className={elemNameClassName}>
            {shopInputElementName}
          </TableCell>
          <TableCell className={elemValueClassName}>
            <Select
              name={name}
              value={shopInput[name] !== '' ? shopInput[name] : ''}
              onChange={handlePullDownChange}
              variant="outlined"
              margin='none'
              className={classes.select}
              classes={{root: classes.selectRoot, select: classes.selectLabel, disabled: classes.disabledString}}
              native
              disabled={name === '業態' || name === '大分類'}
            >
              {name !== '小分類' && pullDownList[name].map((val) => (
                <option value={val} key={val}>{val}</option>
              ))}
              {name === '小分類' && tmpList.map((val) => (
                <option value={val} key={val}>{val}</option>
              ))}
            </Select>
            <Typography
              variant='body1'
              component='div'
              className={classes.unit}
            >
              {unit}
            </Typography>
          </TableCell>
        </TableRow>
      )
    } else if (type === 'none') {
      const elemNameClassName = required ? classes.shopInputDialogItemNameCell + ' ' + classes.required : classes.shopInputDialogItemNameCell;
      const elemValueClassName = classes.shopInputDialogItemValueCell;
      return (
        <TableRow>
          <TableCell className={elemNameClassName}>{name}</TableCell>
          <TableCell className={elemValueClassName}>{name}</TableCell>
        </TableRow>
      )
    }
  }

  const renderShopInput = () =>
    <React.Fragment>
      <Table>
        <TableBody>
          <TableRow className={classes.th1}>
            <TableCell className={classes.inputGroupTitle}>基本属性</TableCell>
          </TableRow>
          <TableRow className={classes.th2}></TableRow>
        </TableBody>
      </Table>
      <Table>
        <TableBody>
          {shopElement('text', '住所', '', true)}
        </TableBody>
      </Table>
      <Grid item xs={6}>
        <Table>
          <TableBody>
            {shopElement('select', '業態', '', true)}
            {shopElement('select', '大分類', '', true)}
            {shopElement('select', '小分類', '', true)}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={6}>
        <Table>
          <TableBody>
            {shopElement('text', '席数', '席')}
            {shopElement('select', '営業時間', '時間')}
            {shopElement('none', '-')}
          </TableBody>
        </Table>
      </Grid>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow className={classes.th3}></TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Table>
        <TableBody>
          <TableRow className={classes.th1}>
            <TableCell className={classes.inputGroupTitle}>敷地/立地</TableCell>
          </TableRow>
          <TableRow className={classes.th2}></TableRow>
        </TableBody>
      </Table>
      <Grid item xs={6}>
        <Table>
          <TableBody>
            {shopElement('text', '敷地面積', '坪')}
            {shopElement('select', '敷地内複合')}
            {shopElement('select', 'コンビニ隣接')}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={6}>
        <Table>
          <TableBody>
            {shopElement('select', '立地タイプ')}
            {shopElement('select', '交差点立地')}
            {shopElement('select', '切下げ有接道数')}
          </TableBody>
        </Table>
      </Grid>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow className={classes.th3}></TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Table>
        <TableBody>
          <TableRow className={classes.th1}>
            <TableCell className={classes.inputGroupTitle}>駐車場/最寄駅</TableCell>
          </TableRow>
          <TableRow className={classes.th2}></TableRow>
        </TableBody>
      </Table>
      <Grid item xs={6}>
        <Table>
          <TableBody>
            {shopElement('text', '台数', '台')}
            {shopElement('text', '充足度', '% ( = 台数 / 席数)')}
            {shopElement('select', '共有')}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={6}>
        <Table>
          <TableBody>
            {shopElement('text', '最寄駅')}
            {shopElement('text', '乗降客数', '人')}
            {shopElement('text', '距離', 'm')}
          </TableBody>
        </Table>
      </Grid>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow className={classes.th3}></TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Table>
        <TableBody>
          <TableRow className={classes.th1}>
            <TableCell className={classes.inputGroupTitle}>周辺道路(メイン)</TableCell>
          </TableRow>
          <TableRow className={classes.th2}></TableRow>
        </TableBody>
      </Table>
      <Grid item xs={6}>
        <Table>
          <TableBody>
            {shopElement('text', '間口', 'm')}
            {shopElement('select', 'メイン道路種類')}
            {shopElement('select', '分離帯有無')}
            {shopElement('text', '交通センサス合計', '台')}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={6}>
        <Table>
          <TableBody>
            {shopElement('text', 'トータル車線数', '車線')}
            {shopElement('text', '店側車線数', '車線')}
            {shopElement('text', '1車線当たり交通量', '台')}
            {shopElement('none', '-')}
          </TableBody>
        </Table>
      </Grid>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow className={classes.th3}></TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Table>
        <TableBody>
          <TableRow className={classes.th1}>
            <TableCell className={classes.inputGroupTitle}>周辺道路(側道)</TableCell>
          </TableRow>
          <TableRow className={classes.th2}></TableRow>
        </TableBody>
      </Table>
      <Grid item xs={6}>
        <Table>
          <TableBody>
            {shopElement('text', '間口 ※車道のみ', 'm')}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={6}>
        <Table>
          <TableBody>
            {shopElement('select', 'サブ1道路種類')}
          </TableBody>
        </Table>
      </Grid>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow className={classes.th3}></TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>

  const dialogForm = () => {
    return (
      <React.Fragment>
        <Table>
          <TableBody>
            <TableRow className={classes.th1}>
              <TableCell className={classes.inputGroupTitle}>物件</TableCell>
            </TableRow>
            <TableRow className={classes.th2}></TableRow>
          </TableBody>
        </Table>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.dialogItemNameCell}>住所</TableCell>
              <TableCell className={classes.dialogItemValueCell}>
                {selectedPlace.name !== undefined ?
                  selectedPlace.name.split(' ')[1] : selectedPlace.name}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Grid container style={{display: 'flex', justifyContent: 'flex-end', padding: '5px 0 15px'}}>
          <React.Fragment>
          <Button
              onClick={openSubDialog}
              className={classes.subDialogButton}
              variant='contained'
              component="span"
              classes={{label: classes.subDialogButtonString}}
              disabled={progress}
              >
              入力
              </Button>
          </React.Fragment>
        </Grid>
        <Table>
          <TableBody>
            <TableRow className={classes.th1}>
              <TableCell className={classes.inputGroupTitle}>周辺店舗</TableCell>
            </TableRow>
            <TableRow className={classes.th2}></TableRow>
          </TableBody>
        </Table>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.dialogItemNameCell}>対象</TableCell>
              <TableCell className={classes.dialogItemValueCell}>
                <FormGroup row>
                  <FormControlLabel
                    label='飲食店'
                    control={
                      <Checkbox
                        checked={true}
                        name='restaurant'
                        color='primary'
                        classes={{root: classes.checkbox}}
                      />
                    }
                    classes={{label: classes.formControlLabelString}}
                    style={{minWidth: '100px'}}
                    disabled={progress}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={true}
                        name='supermarket'
                        color='primary'
                        classes={{root: classes.checkbox}}
                      />
                    }
                    label='スーパー'
                    classes={{label: classes.formControlLabelString}}
                    style={{minWidth: '140px'}}
                    disabled={progress}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={true}
                        name='home_goods_store'
                        color='primary'
                        classes={{root: classes.checkbox}}
                      />
                    }
                    label='ホームセンター'
                    classes={{label: classes.formControlLabelString}}
                    style={{minWidth: '140px'}}
                    disabled={progress}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.department_store}
                        onChange={handleChange}
                        name='department_store'
                        color='primary'
                        classes={{root: classes.checkbox}}
                      />
                    }
                    label='デパート'
                    classes={{label: classes.formControlLabelString}}
                    style={{minWidth: '100px'}}
                    disabled={true}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={true}
                        name='drugstore'
                        color='primary'
                        classes={{root: classes.checkbox}}
                      />
                    }
                    label='ドラッグストア'
                    classes={{label: classes.formControlLabelString}}
                    style={{minWidth: '140px'}}
                    disabled={progress}
                  />
                </FormGroup>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Grid container style={{display: 'flex', justifyContent: 'flex-end', padding: '5px 0 15px'}}>
          <Button
            onClick={getNearShop}
            className={classes.subDialogButton}
            variant='contained'
            classes={{label: classes.subDialogButtonString}}
            disabled={progress}
          >
            取得
          </Button>
        </Grid>
        <Table>
          <TableBody>
            <TableRow className={classes.th1}>
              <TableCell className={classes.inputGroupTitle}>予測</TableCell>
            </TableRow>
            <TableRow className={classes.th2}></TableRow>
          </TableBody>
        </Table>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.dialogItemNameSmallCell}>物件入力</TableCell>
              <TableCell className={classes.dialogItemValueSmallCell + ' ' + (isInputReady[placeName] ? classes.readyColor : classes.notReadyColor)}>
                {isInputReady[placeName] ? '済' : '未'}
              </TableCell>
              <TableCell className={classes.dialogItemNameSmallCell}>周辺店舗取得</TableCell>
              <TableCell className={classes.dialogItemValueSmallCell + ' ' + (isNearbyReady[placeName] ? classes.readyColor : classes.notReadyColor)}>
                {isNearbyReady[placeName] ? '済': '未'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.dialogItemNameCell}>予測金額</TableCell>
              <TableCell colSpan={3} className={classes.dialogItemValueCell}>
                <FormControl className={classes.formControl}>
                  {selectedPlace.name === undefined || predictionResult[selectedPlace.name.split(' ')[1]] === undefined ? '' : Math.ceil(predictionResult[selectedPlace.name.split(' ')[1]]['predictions']).toLocaleString() + '円'}
                </FormControl>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Grid container style={{display: 'flex', justifyContent: 'flex-end', padding: '5px 0 15px'}} spacing={1}>
          <Grid item>
            <Button
              onClick={handlePrediction}
              disabled={
                !(isInputReady[placeName] && isNearbyReady[placeName])
              }
              variant='contained'
              className={classes.subDialogButton}
              classes={{label: classes.subDialogButtonString}}
            >
              {'実行'}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleCrtPDF}
              variant='contained'
              disabled={!selectedPlace.name || !finishPrediction[selectedPlace.name.split(' ')[1]]}
              className={classes.subDialogButton}
              classes={{label: classes.subDialogButtonString}}
            >
              {'結果表出力'}
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  const checkKeyShopInput = () => {
    let keyVals = KeyShopInfo.map((key) => shopInput[key]);
    return keyVals.indexOf('') === -1;
  };

  const renderSubDialog = (
    <Dialog
      open={showDialog}
      onClose={closeSubDialog}
      aria-labelledby='form-dialog-title'
      classes={{paper: classes.subDialogPaper}}
    >
      <DialogTitle style={{padding: '0px'}}>
        <Grid container style={{background: 'lightgray', padding: '3px 13px'}}>
          <Grid item xs={6}>
            <Typography variant='body1' component='h6'>
              {'物件入力'}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{root: classes.dialogContentRoot}}>
      {/* <Grid container style={{padding: '3px 16px'}}> */}
      <Grid container>
          <Grid item xs={6} style={{display: 'table'}}>
            <Typography
              variant='body1'
              align='left'
              display='inline'
              style={{display: 'table-cell',verticalAlign: 'middle', fontWeight: 'bold'}}
              className={classes.shopInputFont}
            >
              {'※:項目名が青の項目は必須入力'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <DialogActions style={{paddingRight: '0'}}>
              <Button
                onClick={saveInput}
                disabled={!checkKeyShopInput()}
                className={classes.subDialogButton}
                variant='contained'
                classes={{label: classes.subDialogButtonString}}
              >
                  {'登録'}
              </Button>
              <Button
                onClick={setDefault}
                className={classes.subDialogButton}
                variant='contained'
                classes={{label: classes.subDialogButtonString, disabled: classes.subDialogButtonDisabled}}
              >
                  クリア
              </Button>
              <Button
                onClick={closeSubDialog}
                className={classes.subDialogButton}
                variant='contained'
                classes={{label: classes.subDialogButtonString}}
              >
                  {'閉じる'}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
        <Grid container style={{padding: '5px', border: 'solid lightgray 1px'}}>
          {renderShopInput()}
        </Grid>
      </DialogContent>
    </Dialog>
  );

  const renderProgress = (
    <>
      <Dialog open={progress} classes={{paper: classes.progressDialog}}>
        <DialogTitle style={{background: 'lightgray', padding: '3px 13px'}}>
          <Typography variant='body1' component='div'>
            処理中
          </Typography>
        </DialogTitle>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  )

  return (
    <div style={{position: 'relateive', zIndex: '1000', background: 'white'}}>
      <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title' classes={{paper: classes.dialogPaper}} style={{minWidth: '1000px'}}>
        <DialogTitle style={{padding: '0px'}}>
          <Grid container style={{background: 'lightgray', padding: '3px 13px'}}>
            <Typography variant='body1' component='h6'>
              {title}
            </Typography>
          </Grid>
          <Grid container style={{padding: '3px 8px', display: 'flex', justifyContent: 'flex-end'}}>
            <DialogActions classes={{root: classes.closeButtonPadding}}>
              <Button
                onClick={(e) => {
                  onClose();
                  setState({
                    restaurant: true,
                    supermarket: true,
                    home_goods_store: true,
                    department_store: false,
                    drugstore: true,
                  });
                  setErrMsg('');
                  setShopInput(shopInputDefault);
                }}
                variant='contained'
                className={classes.subDialogButton}
                classes={{label: classes.subDialogButtonString}}
                disabled={progress}
              >
                {'閉じる'}
              </Button>
            </DialogActions>
          </Grid>
        </DialogTitle>

        <DialogContent id='dialogContent' classes={{root: classes.dialogContentRoot}}>
          <Grid container style={{padding: '5px', border: 'solid lightgray 1px'}}>
            {dialogForm()}
          </Grid>
        </DialogContent>
      </Dialog>
      {renderSubDialog}
      {renderProgress}
      <PredContent
        id={'divtoPDF'}
        title={'予測結果'}
        name={'予測結果'}
        para={''}
        dataset={''}
        shopType={''}
        predictRes={placeName === '' || predictionResult[placeName] === undefined ? '' : predictionResult[placeName]}
        onOpenShopInfo={openSubDialog}
        photos={photos}
        memo={memo}
        shopInput={shopInput}
      />
      <Alert open={errOpen} handleClose={handleErrDialogClose} msg={errMsg} okBtnName={'閉じる'} />
    </div>
  );
}
