import styled from "styled-components";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";

const Card = styled.div`
  border-color: #9b9b9b;
  box-sizing: border-box;
  max-width: 400px;
  margin: 0 auto;
  padding: 15rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  box-sizing: border-box;
  color: #9b9b9b;
  text-align: left;
  width: 100%;
  margin-bottom: 0.2rem;
`;

const Paper = styled.div`
  box-sizing: border-box;
  border: 1px solid #c1c1c1;
  border-radius: 2px;
  width: 290px;
  padding: 0.8rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.5rem;
`;

const Chart1 = styled.div`
  border: 1px solid #c1c1c1;
  border-bottom: none;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Chart2 = styled.div`
  border: 1px solid #c1c1c1;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SubTitle = styled.div`
  color: #9b9b9b;
  padding: 3px;
  padding-left: 3px;
  text-align: left;
  font-size: 14px;
  width: 90px;
  background: #e8e8e8;
  display: flex;
  align-items: center;
  height: 30px;
`;

const PassWordTitle = styled.div`
  color: #9b9b9b;
  padding: 3px;
  padding-left: 3px;
  text-align: left;
  font-size: 14px;
  width: 110px;
  background: #e8e8e8;
  display: flex;
  align-items: center;
  height: 30px;
`;

const Form = styled.div`
  border-left: 1px solid #c1c1c1;
  padding: 0rem;
  display: flex;
  width: 60%;
  align-items: center;
  padding-left: 4px;
`;

const Input = styled.input`
  border: 1px solid rgba(180,180,180,87);
  border-radius: 3px;
  padding: 0.3rem;
  font-size: 0.8rem;
  width: 120px;
  box-shadow: 0 0 0px 1000px #fff inset;
  &:hover {
    border: 1px solid rgba(0,0,0,255);
  }
`;
const PasswordInput = styled.input`
  border: 1px solid rgba(180,180,180,87);
  border-radius: 3px;
  padding: 0.3rem;
  font-size: 0.8rem;
  width: 105px;
  box-shadow: 0 0 0px 1000px #fff inset;
  &:hover {
    border: 1px solid rgba(0,0,0,255);
  }
`;

const Lower = styled.div`
  width: 100%;
  margin: 0 0 0 auto;
  text-align: right;
`;

const Button = styled.button`
  border: 1px solid #4f4f4f;
  border-radius: 3px;
  background: #e6e6e6;
  padding: 0.3rem;
  color: #4f4f4f;
  font-weight: normal;
  width: 100px;
  margin-left: 0.3rem;
  font-size: 12px;
`;

const Error = styled.div`
  background-color: red;
`;

export {
  Form,
  Chart1,
  Chart2,
  Input,
  Button,
  Card,
  Error,
  Paper,
  Title,
  Content,
  Lower,
  SubTitle,
  PassWordTitle,
  PasswordInput
};
