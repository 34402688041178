import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import {
  Card,
  Form,
  Chart1,
  Chart2,
  Input,
  Button,
  Paper,
  Title,
  Content,
  Lower,
  SubTitle,
} from "../components/UserAuthForm";
import { useAuth } from "../context/auth";
import { useUser } from "../context/user";
import SimpleBackdrop from "../components/CirProgress";
import Dialog from "@material-ui/core/Dialog";

function Login(props) {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [showProgBar, setShowProgBar] = useState(false);
  const [user_id, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthTokens } = useAuth();
  const { setSignedInUser } = useUser();

  function postLogin() {
    setShowProgBar(true);

    const UserId_task = () => {
      return new Promise( (callback) => {
        localStorage.setItem("currentUserId", user_id)
        setSignedInUser({
          uid: user_id,
          firstName: user_id,
          lastName: '',
        });
        callback();
      })
    }

    const UserTokens_task = (result) => {
      return new Promise( (callback) => {
        localStorage.setItem("userAuthToken", result.data.token);
        setAuthTokens(result.data.token);
        localStorage.setItem("loginTime", new Date);
        callback();
      })
    }

    axios
      .post(process.env.REACT_APP_AUTH_SERVER + "auth/login", {
        user_id,
        password,
      })
      .then((result) => {
        if (result.data.token.length > 0) {
          const p1 = UserId_task();
          const p2 = UserTokens_task(result);

          // localStorage入力後にログインするように対応
          Promise.all([p1, p2]).then(() => {
            setShowProgBar(false);
            setLoggedIn(true);
          });
        }
        else {
          alert(result.data.message);
          setShowProgBar(false);
        }
      })
      .catch((e) => {
        if (e.response.status === 461 || e.response.status === 463) {
          alert(e.response.data.message);
          window.location = `/reset-pwd?user_id=${user_id}&token=${e.response.data.token}`;
        }
        else{
          alert(e.response.data.message);
        }
        setShowProgBar(false);
      });
  }

  function postResetPwd() {
    axios
      .post(process.env.REACT_APP_AUTH_SERVER + "auth/login", {
        user_id,
        password,
      })
      .then((result) => {
        if (result.data.token.length > 0) {
          window.location = `/reset-pwd?user_id=${user_id}&token=${result.data.token}`;
        } else {
          alert(result.data.message);
        }
      })
      .catch((e) => {
        if (e.response.status === 461 || e.response.status === 463) {
          window.location = `/reset-pwd?user_id=${user_id}&token=${e.response.data.token}`;
        }
        alert(e.response.data.message);
        setShowProgBar(false);
      });
  }

  const referer =
    props.location.state !== undefined
      ? props.location.state.referer
      : "/";

  if (isLoggedIn) {
    return <Redirect to={referer} />;
  }
  return (
    <div>
      <Dialog open fullScreen={true}>
        <SimpleBackdrop open={showProgBar} />
        <Card>
          <Title>■ ユーザー認証</Title>
          <Paper>
            <Content>
              <Chart1>
                <SubTitle>ユーザーID</SubTitle>
                <Form>
                  <Input
                    type="username"
                    name={user_id}
                    onChange={(e) => {
                      setUserId(e.target.value);
                    }}
                    style={{ boxShadow: "0 0 0px 1000px #fff inset"}}
                  />
                </Form>
              </Chart1>
              <Chart2>
                <SubTitle>パスワード</SubTitle>
                <Form>
                  <Input
                    type="password"
                    name={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    style={{ boxShadow: "0 0 0px 1000px #fff inset"}}
                  />
                </Form>
              </Chart2>
            </Content>

            <Lower>
              <Button onClick={postLogin}>ログイン</Button>
              <Button onClick={postResetPwd}>パスワード変更</Button>
            </Lower>
          </Paper>
        </Card>
      </Dialog>
    </div>
  );
}
export default Login;
