import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Card,
  Form,
  Chart1,
  Chart2,
  Button,
  Paper,
  Title,
  Content,
  Lower,
  PassWordTitle,
  PasswordInput
} from "../components/UserAuthForm";
import axios from "axios";
import { useAuth } from "../context/auth";
import { useUser } from "../context/user";
import SimpleBackdrop from "../components/CirProgress";
import Dialog from "@material-ui/core/Dialog";

function ResetPwd(props) {
  const [isReset, setReset] = useState(false);
  const [showProgBar, setShowProgBar] = useState(false);
  const [new_password, setNewPassword] = useState("");
  const [again_password, setAgainPassword] = useState("");
  const [hasSent, setHasSent] = useState(false);
  const { setAuthTokens } = useAuth();
  const { setSignedInUser } = useUser();

  function visitPage() {
    window.location = "/login";
  }

  if (isReset) {
    return <Redirect to='/login'/>;
  }

  // 現在時刻取得
  function date_get_today() {
    var date = new Date();
    // フォーマット変更
    var r_format_date = date_format_change(date);
    return r_format_date;
  }

  // フォーマット変更
  function date_format_change(date) {
    let year_str = date.getFullYear();
    // 月だけ+1すること
    let month_str = 1 + date.getMonth();
    let day_str = date.getDate();
    let hour_str = date.getHours();
    let minute_str = date.getMinutes();
    let second_str = date.getSeconds();

    let format_str = "YYYY/MM/DD hh:mm:ss";
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    format_str = format_str.replace(/hh/g, hour_str);
    format_str = format_str.replace(/mm/g, minute_str);
    format_str = format_str.replace(/ss/g, second_str);

    return format_str;
  }

  function postResetPwd() {
    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get("user_id");
    const token = urlParams.get("token");
    const update_user_id = user_id;
    const update_date = date_get_today();
    setShowProgBar(true);
    axios
      .request({
        method: "put",
        url: process.env.REACT_APP_AUTH_SERVER + "auth/resetPwd",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          user_id,
          new_password,
          again_password,
          update_user_id,
          update_date,
        },
      })
      .then((result) => {
        if (result.status === 200) {
          setHasSent(true);
        } else {
          alert(result.data.message);
        }
        setShowProgBar(false);
      })
      .catch((e) => {
        alert(e.response.data.message);
        setShowProgBar(false);
        switch (e.response?.status) {
          case 400:
          case 401:
            setAuthTokens(null);
            setSignedInUser(null);
        }
      });
  }

  const referer =
    props.location.state !== undefined
      ? props.location.state.referer
      : "/";

  if (hasSent) {
    return <Redirect to={referer} />;
  }

  return (
    <div>
      <Dialog open fullScreen={true}>
        <SimpleBackdrop open={showProgBar} />
        <Card>
          <Title>■ パスワード変更</Title>
          <Paper>
            <Content>
              <Chart1>
                <PassWordTitle>新パスワード</PassWordTitle>
                <Form>
                  <PasswordInput
                    type="password"
                    value={new_password}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                    style={{ boxShadow: "0 0 0px 1000px #fff inset"}}
                  />
                </Form>
              </Chart1>
              <Chart2>
              <PassWordTitle>確認パスワード</PassWordTitle>
                <Form>
                  <PasswordInput
                    type="password"
                    value={again_password}
                    onChange={(e) => {
                      setAgainPassword(e.target.value);
                    }}
                    style={{ boxShadow: "0 0 0px 1000px #fff inset"}}
                  />
                </Form>
              </Chart2>
            </Content>

            <Lower>
              <Button onClick={postResetPwd}>変更</Button>
              <Button onClick={visitPage}>戻る</Button>
            </Lower>
          </Paper>
        </Card>
      </Dialog>
    </div>
  );
}
export default ResetPwd;
