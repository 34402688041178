export const shopInputDefault = {
  // select項目は空文字列、text項目はnull
  '住所': null,
  '業態': 'かつや',
  '大分類': '(RS)ロードサイド',
  '小分類': '',
  '席数': null,
  '営業時間': '',
  '敷地面積': null,
  '敷地内複合': '',
  'コンビニ隣接': '',
  '立地タイプ': '',
  '交差点立地': '',
  '切下げ有接道数': '',
  '台数': null,
  '充足度': null,
  '共有': '',
  '最寄駅': null,
  '乗降客数': null,
  '距離': null,
  '間口': null,
  'メイン道路種類': '',
  '分離帯有無': '',
  '交通センサス合計': null,
  'トータル車線数': null,
  '店側車線数': null,
  '1車線当たり交通量': null,
  '間口 ※車道のみ': null,
  'サブ1道路種類': '',
};

export const shopInputNameMapping = {
  '住所': 'address',
  '業態': 'gyotai',
  '大分類': 'category',
  '小分類': 'sub_category',
  '席数': 'number_of_seats',
  '営業時間': 'business_hours',
  '敷地面積': 'site_surface',
  '敷地内複合': 'shopping_complex',
  'コンビニ隣接': 'nearest_convenience_store',
  '立地タイプ': 'location_type',
  '交差点立地': 'crossroad',
  '切下げ有接道数': 'cutting_down_the_number_of_connections',
  '台数': 'number_of_parking',
  '充足度': 'fill_parking',
  '共有': 'shared_parking',
  '最寄駅': 'nearest_station',
  '乗降客数': 'number_of_passengers_nearest_station',
  '距離': 'distance_nearest_station',
  '間口': 'main_frontage',
  'メイン道路種類': 'main_road_type',
  '分離帯有無': 'main_separation_zone',
  '交通センサス合計': 'main_traffic_census',
  'トータル車線数': 'main_total_number_of_lanes',
  '店側車線数': 'number_of_main_shop_side_lanes',
  '1車線当たり交通量': 'main_traffic_volume_per_lane',
  '間口 ※車道のみ': 'sideroad1_frontage',
  'サブ1道路種類': 'sideroad1_type',
};

export const shopInfo = Object.keys(shopInputDefault);

export const allShopInfo = [
  '業態',
  '大分類',
  '小分類',
  '店名',
  '住所',
  '営業時間',
  '最寄駅',
  '最寄駅までの距離',
  '最寄駅乗降客数',
  '席数',
  '駐車場台数',
  '駐車場共有',
  '大型車',
  '駐車場充足度',
  '敷地面積(坪数)',
  'メイン車線交通量',
  '交通センサス合計値',
  '間口(車道)',
  'メイン切下げ箇所(合計)',
  'サブ切下げ箇所(合計)',
  'メイン車線数',
  '分離帯(1-3)',
  '切下げ有接道数',
  '切下げ位置',
  'コンビニ隣接(0-3)',
  '立地タイプ',
  '敷地内複合有/無',
  '複合店舗数(合計)',
  '複合飲食店',
  'その他店舗数',
  '路肩',
  '反対車線進入用右折レーン有/無',
  'メイン切下げ有無',
  'メイン道路幅※反対車線含む( m )',
  'メイン切下げ(進入可能)箇所数',
  'メイン切下げ幅①',
  'メイン切下げ幅②',
  'メイン道路の種類',
  'メイントータル車線数',
  'メイン店側車線数',
  'メイン中央分離帯有/無',
  'メイン中央分離帯種類',
  'メイン歩道有/無',
  'メイン歩道幅',
  'メイン横断歩道有/無',
  'メイン歩道橋有/無',
  'メイン50m圏内バス停有/無',
  'メイン上バイパス高速道路有/無',
  'メインから高速入口まで(距離)',
  '高速出口からメインまで(距離)',
  'メイン同一車線店手前500m競合店数',
  'メイン同一車線店の先500m競合店数',
  'メイン交通センサス',
  'メインアプローチ',
  'メイン電柱・電線有/無',
  'メイン街路樹位置',
  'サブ１切下げ有無',
  'サブ１道路幅※反対車線含む( m )',
  'サブ１切下げ(進入可能)箇所数',
  'サブ１切下げ幅①',
  'サブ１切下げ幅②',
  'サブ１道路の種類',
  'サブ１トータル車線数',
  'サブ１店側車線数',
  'サブ１中央分離帯有/無',
  'サブ１中央分離帯種類',
  'サブ１歩道有/無',
  'サブ１歩道幅(m)',
  'サブ１横断歩道有/無',
  'サブ１歩道橋有/無',
  'サブ１50m圏内バス停有/無',
  'サブ１上バイパス高速道路有/無',
  'サブ１から高速入口まで(距離)',
  '高速出口からサブ１まで(距離)',
  'サブ１同一車線店手前500m競合店数',
  'サブ１同一車線店の先500m競合店数',
  'サブ１交通センサス',
  'サブ１アプローチ',
  'サブ１電柱・電線',
  'サブ１街路樹',
  'サブ２切下げ有無',
  'サブ２道路幅※反対車線含む',
  'サブ２切下げ(進入可能)箇所数',
  'サブ２切下げ幅①',
  'サブ２切下げ幅②',
  'サブ２道路の種類',
  'サブ２トータル車線数',
  'サブ２店側車線数',
  'サブ２中央分離帯有/無',
  'サブ２中央分離帯種類',
  'サブ２歩道有/無',
  'サブ２歩道幅(m)',
  'サブ２横断歩道有/無',
  'サブ２歩道橋有/無',
  'サブ２50m圏内バス停有/無',
  'サブ２上バイパス高速道路有/無',
  'サブ２から高速入口まで(距離)',
  '高速出口からサブ２まで(距離)',
  'サブ２同一車線店手前500m競合店数',
  'サブ２同一車線店の先500m競合店数',
  'サブ２交通センサス',
  'サブ２アプローチ',
  'サブ２電柱・電線',
  'サブ２街路樹',
  'サブ3切下げ有無',
  'サブ３道路幅※反対車線含む( m )',
  'サブ３切下げ(進入可能)箇所数',
  'サブ３切下げ幅①',
  'サブ３切下げ幅②',
  'サブ３道路の種類',
  'サブ３トータル車線数',
  'サブ３店側車線数',
  'サブ３中央分離帯有/無',
  'サブ３中央分離帯種類',
  'サブ３歩道有/無',
  'サブ３歩道幅(m)',
  'サブ３横断歩道有/無',
  'サブ３歩道橋有/無',
  'サブ３50m圏内バス停有/無',
  'サブ３上バイパス高速道路有/無',
  'サブ３から高速入口まで(距離)',
  '高速出口からサブ３まで(距離)',
  'サブ３同一車線店手前500m競合店数',
  'サブ３同一車線店の先500m競合店数',
  'サブ３交通センサス',
  'サブ３アプローチ',
  'サブ３電柱・電線',
  'サブ３街路樹',
  'メイン店前ガードレール',
  '渋滞',
  '車速',
  '視認性',
  'ビジネス要素(徒歩10分)',
  '繁華街要素(徒歩10分)',
  '商店街要素(徒歩10分)',
  '学生街要素(徒歩10分)',
  '住宅街要素(徒歩10分)',
  '歓楽街要素(徒歩10分)',
  'インバウンド要素(徒歩10分)',
  '国内観光要素(徒歩10分)',
  '郊外中心都市要素(徒歩10分)',
  '出店階',
  'ＢＩ袖看板(数)',
  'ＢＩ袖看板(高さ)',
  'ＢＩスタンド看板(数)',
  'ＢＩイーゼル(数)',
  'ＢＩのぼり(数)',
  '鉄道乗り入れ本数',
  '駅ロータリー数',
  'ロータリー位置',
];

export const strShopInfo = [
  '住所',
  '業態',
  '大分類',
  '小分類',
  '敷地内複合',
  '立地タイプ',
  '交差点立地',
  '最寄駅',
  'コンビニ隣接',
  '共有',
  'メイン道路種類',
  '分離帯有無',
  'サブ1道路種類'
];

export const pullDownList = {
  // add spcae to reperesent null
  '業態': ['','かつや','からやま'],
  '大分類': ['','(RS)ロードサイド','(BI)ビルイン'],
  '小分類': ['(R)ノーマルタイプ','(ER)駅チカ(～800m)','(FR)複合・施設内','(GR)駅チカ+複合・施設内','(BR)ビルインタイプ'],
  '営業時間' : ['','0','0.5','1','1.5','2','2.5','3','3.5','4','4.5','5','5.5','6','6.5','7','7.5','8','8.5','9','9.5','10','10.5','11','11.5','12','12.5','13','13.5','14','14.5','15','15.5','16','16.5','17','17.5','18','18.5','19','19.5','20','20.5','21','21.5','22','22.5','23','23.5','24'],
  '敷地内複合': ['','(A)複合先あり','(B)複合先なし'],
  'コンビニ隣接': ['','(0)隣接なし','(1)歩道を渡るとあり','(2)敷地別の隣接','(3)同一敷地内に隣接'],
  '立地タイプ': ['','(A)信号あり交差点先','(B)信号機あり交差点手前','(C)信号機なし交差点','(D)三叉路・五叉路など','(E)中央分離帯なしＴ字路','(F)一面道路(接道1)','(G)中央分離帯ありＴ字路','(H)一面道路(接道2)'],
  '交差点立地': ['','(A)交差点に面している','(B)交差点に面していない'],
  '共有':['','(0)共有なし','(1)他テナントと共有'],
  'メイン道路種類': ['','(1)合計の車線数が１','(2)合計の車線数が２','(3)合計の車線数が３','(4)都道府県道または合計の車線数が４以上','(5)国道または合計の車線数が５以上'],
  '分離帯有無': ['','(1)分離帯あり','(2)乗り越えられる障害あり','(3)分離帯なし'],
  'サブ1道路種類': ['','(0)サブ１(側道)なし','(1)合計の車線数が１','(2)合計の車線数が２','(3)合計の車線数が３','(4)都道府県道または合計の車線数が４以上','(5)国道または合計の車線数が５以上'],
  '切下げ有接道数': ['','1','2','3','4']
};

export const shopInputValueMapping = {
  '(RS)ロードサイド': 'RS',
  '(BI)ビルイン': 'BI',
  '(R)ノーマルタイプ': 'R',
  '(BR)ビルインタイプ': 'BR',
  '(ER)駅チカ(～800m)': 'ER',
  '(FR)複合・施設内': 'FR',
  '(GR)駅チカ+複合・施設内': 'GR',
  '(A)複合先あり': 'A',
  '(B)複合先なし': 'B',
  '(0)隣接なし': 0,
  '(1)歩道を渡るとあり': 1,
  '(2)敷地別の隣接': 2,
  '(3)同一敷地内に隣接': 3,
  '(A)信号あり交差点先': 'A',
  '(B)信号機あり交差点手前': 'B',
  '(C)信号機なし交差点': 'C',
  '(D)三叉路・五叉路など': 'D',
  '(E)中央分離帯なしＴ字路': 'E',
  '(F)一面道路(接道1)': 'F',
  '(G)中央分離帯ありＴ字路': 'G',
  '(H)一面道路(接道2)': 'H',
  '(A)交差点に面している': 'A',
  '(B)交差点に面していない': 'B',
  '(0)共有なし': 0,
  '(1)他テナントと共有': 1,
  '(1)分離帯あり': 1,
  '(2)乗り越えられる障害あり': 2,
  '(3)分離帯なし': 3,
  '(0)サブ１(側道)なし': 0,
  '(1)合計の車線数が１': 1,
  '(2)合計の車線数が２': 2,
  '(3)合計の車線数が３': 3,
  '(4)都道府県道または合計の車線数が４以上': 4,
  '(5)国道または合計の車線数が５以上': 5
};

export const features = [
  '世帯数5分',
  '件数_750m(HCDS)',
  'SM_7',
  '競合店_6',
  '件数_100m(競合店)',
  '件数_3km(かつや店舗)',
  '直線距離(m)_9(競合店)',
  '直線距離(m)_9(HCDS)',
  'HCDS_2',
  '昼間第2･3次産業人口10分',
  '直線距離(m)_3(SM)',
  'HCDS_8',
  '昼間人口総数10分',
  '直線距離(m)_4(SM)',
  '直線距離(m)_10(SM)',
  '直線距離(m)_2(かつや店舗)',
  '人口総数10分',
  '件数_3km(競合店)',
  '昼間生徒･学生総数10分',
  '直線距離(m)_8(SM)',
  '直線距離(m)_5(かつや店舗)',
  '飲食料品小売業事業所数5分',
  'SM_1',
  '世帯数10分',
  '直線距離(m)_3(競合店)',
  '件数_2km(競合店)',
  '競合店_3',
  '直線距離(m)_3(かつや店舗)',
  '件数_1km(SM)',
  '昼間人口総数5分',
  '件数_250m(競合店)',
  '競合店_4',
  '件数_2km(HCDS)',
  '平均所得_市区町村別',
  'year',
  '飲食店事業所数10分',
  'HCDS_1',
  '八地方区分',
  '昼間第2･3次産業人口5分',
  'エリア数5分',
  '件数_1km(かつや店舗)',
  '直線距離(m)_2(HCDS)',
  '競合店_1',
  '直線距離(m)_3(HCDS)',
  '件数_100m(HCDS)',
  'HCDS_3',
  '昼間生徒･学生総数5分',
  'open_year',
  '小売業事業所数5分',
  '飲食料品小売業事業所数10分',
  '直線距離(m)_6(競合店)',
  '平均年収の順位_市区町村別',
  '直線距離(m)_10(競合店)',
  '直線距離(m)_8(競合店)',
  '直線距離(m)_4(HCDS)',
  '直線距離(m)_9(SM)',
  '件数_3km(HCDS)',
  '直線距離(m)_6(SM)',
  '直線距離(m)_5(HCDS)',
  'SM_8',
  '競合店_9',
  '直線距離(m)_5(SM)',
  '直線距離(m)_7(SM)',
  '全産業事業所数10分',
  '人口総数5分',
  '競合店_2',
  '直線距離(m)_5(競合店)',
  '直線距離(m)_7(HCDS)',
  '競合店_10',
  'HCDS_4',
  '死亡者数(住民票消除数)',
  '全産業事業所数5分',
  '増減数[計（住民票記載数） - 計(住民票消除数)]',
  'year_diff_open_year',
  '直線距離(m)_2(競合店)',
  '競合店_7',
  '間口（車道）',
  '直線距離(m)_2(SM)',
  '直線距離(m)_1(競合店)',
  '直線距離(m)_6(HCDS)',
  '転入者数（住民票記載数）',
  '自然増減数',
  '世帯数',
  '直線距離(m)_7(競合店)',
  '自然増減率',
  '直線距離(m)_4(かつや店舗)',
  '件数_750m(競合店)',
  '直線距離(m)_10(HCDS)',
  '競合店_8',
  '飲食店事業所数5分',
  '直線距離(m)_8(HCDS)',
  '直線距離(m)_1(SM)',
  '男性人口総数',
  '直線距離(m)_1(HCDS)',
  '直線距離(m)_4(競合店)',
  'HCDS_6',
  'SM_6',
  'HCDS_5',
  'その他(住民票消除数)',
  '件数_3km(SM)',
  '人口総数（男性+女性）',
  '競合店_5',
  '社会増減率',
  '増減率',
  '小売業事業所数10分',
  '出生者数（住民票記載数）',
  '転出者数(住民票消除数)',
  '件数_500m(HCDS)',
  '計（住民票記載数）',
  '件数_500m(SM)',
  '件数_750m(SM)',
  '女性人口総数',
  '件数_250m(HCDS)',
  'その他（住民票記載数）',
  '計(住民票消除数)',
  '件数_500m(競合店)',
  '社会増減数',
  '件数_2km(SM)',
  'SM_4',
  'HCDS_9',
  '件数_250m(SM)',
  '件数_1km(競合店)',
  '件数_750m(かつや店舗)',
  '件数_500m(かつや店舗)',
  '件数_250m(かつや店舗)',
  '件数_2km(かつや店舗)',
  'からやまフラグ',
  '件数_100m(かつや店舗)',
  'SM_10',
  '直線距離(m)_1(かつや店舗)',
  '件数_100m(SM)',
  'SM_9',
  'エリア数10分',
  'かつや店舗_5',
  'かつや店舗_4',
  'かつや店舗_3',
  'かつや店舗_2',
  'かつや店舗_1',
  'HCDS_10',
  'HCDS_7',
  'SM_2',
  'SM_3',
  '件数_1km(HCDS)',
  'SM_5',
].concat(shopInfo);

export const featureVals = [
  0.13603787,
  0.0779746,
  0.05579876,
  0.051287655,
  0.046050824,
  0.041716456,
  0.030122304,
  0.026029656,
  0.020708567,
  0.01946178,
  0.017530682,
  0.016732616,
  0.014909833,
  0.014286735,
  0.01402271,
  0.012412336,
  0.0119904205,
  0.010379864,
  0.009656679,
  0.009650409,
  0.009572648,
  0.008871131,
  0.008822987,
  0.00838599,
  0.008348341,
  0.00797393,
  0.007887703,
  0.0077700457,
  0.00765918,
  0.0073342947,
  0.0071790637,
  0.0070213894,
  0.006809146,
  0.0064898105,
  0.0064712428,
  0.0060921432,
  0.0060066115,
  0.0059073633,
  0.0057507483,
  0.005692069,
  0.0056709107,
  0.005442715,
  0.0053844056,
  0.005374906,
  0.00528091,
  0.0052565113,
  0.0052191257,
  0.0050169183,
  0.0048598642,
  0.004777002,
  0.004759521,
  0.0047588823,
  0.0047441837,
  0.004515976,
  0.004370342,
  0.0041426504,
  0.004060229,
  0.0039218846,
  0.003894143,
  0.0038300927,
  0.0036667795,
  0.0036078566,
  0.0035344905,
  0.0034212384,
  0.0034149769,
  0.0033688303,
  0.0033437328,
  0.003249913,
  0.003188431,
  0.0031119292,
  0.0029652605,
  0.0029322007,
  0.0029173347,
  0.0028654172,
  0.0028130924,
  0.002689933,
  0.0026491124,
  0.0026059966,
  0.0024876874,
  0.0023739755,
  0.0021888888,
  0.0021719798,
  0.0021304833,
  0.0018809598,
  0.0018714083,
  0.0018084844,
  0.0017866646,
  0.0017861775,
  0.0017428615,
  0.0017401032,
  0.0016848319,
  0.0016206447,
  0.0015966168,
  0.0015960329,
  0.0015032553,
  0.0014948787,
  0.0014779563,
  0.0014310263,
  0.0013830838,
  0.0012209297,
  0.0011268447,
  0.0010095129,
  0.0009922816,
  0.0009894966,
  0.0009814245,
  0.0009586165,
  0.00095382374,
  0.00093124993,
  0.0009280391,
  0.00090518803,
  0.0008953602,
  0.0008854367,
  0.0008100789,
  0.00078290794,
  0.0007804767,
  0.0007707451,
  0.0007305619,
  0.00070460705,
  0.00067777437,
  0.00067141454,
  0.0006707394,
  0.0006205778,
  0.0005720009,
  0.00056170963,
  0.00054392737,
  0.00053409964,
  0.00053066266,
  0.00052599586,
  0.0005119252,
  0.0004977896,
  0.00049320096,
  0.0004905681,
  0.00044802704,
  0.0003749879,
  0.0003363856,
  0.0002299791,
  0.00021825336,
  0.00020756379,
  0.00019470135,
  0.00016882988,
  0.00015698165,
  0.00012176599,
  9.023144e-5,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
  0.0,
];

export const KeyShopInfo = [
  '住所',
  '業態',
  '大分類',
  '小分類'
];
