import React, { useState , useEffect } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useAuth } from '../context/auth';
import { useUser } from '../context/user';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { Input, Form, Error } from './AuthForm';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import SearchBar from './SearchBar';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PredExcel from './PredExcel';
import UserManagement from './UserManagement';
import {
  // BrowserView,
  // MobileView,
  isBrowser,
  // isMobile
} from "react-device-detect";
import modelPDF from '../images/モデル特性.pdf'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  logo: {
    maxWidth: 160,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  hide: {
    display: 'none',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  avatarRoot: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    // fontSize: 1,
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function PrimarySearchAppBar(props) {
  const { authTokens, setAuthTokens } = useAuth();
  const { setSignedInUser } = useUser();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [imgFiles, setImgFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const cur_user = JSON.parse(localStorage.getItem('user')) || '';

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isError, setIsError] = useState(false);
  const [msg, setMSG] = useState('');
  const [avatar, setAvatar] = useState(cur_user.profileImg || '');
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [alertHandler, setAlertHandler] = useState();
  const [openExcel, setOpenExcel] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const [user_admini_flag, setUserAdminiFlag] = useState(true);
  const [current_user_simei, setCurrentSimei] = useState('');
  const [loginTime, setLoginTime] = useState('');
  const { google, onPlaceLoaded, map } = props;
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleLogoutClick = () => {
    setAuthTokens(null);
    setSignedInUser(null);
  };
  const handleClose = () => {
    setImgFiles([]);
    setFiles([]);
  };
  const handleCapture = (event) => {
    setFiles(event.target.files);
    const files = [].map.call(event.target.files, function (f) {
      return { img: URL.createObjectURL(f), title: f.name, cols: 0 };
    });
    setImgFiles(files);
  };
  const handleProfileOpen = (event) => {
    setOpenProfile(true);
    handleClose();
  };
  const handleProfileClose = (event) => {
    setOpenProfile(false);
    handleClose();
  };
  const handlePost = () => {
    const uid = cur_user.uid;
    const token = authTokens;
    axios
      .patch(process.env.REACT_APP_AUTH_SERVER + 'users/updateName', {
        uid,
        firstName,
        lastName,
        token,
      },{
        headers: {
          Authorization: `Bearer ${authTokens}`,
          ContentType: 'application/json',
        }
      })
      .then((result) => {
        if (result.status === 200) {
          setSignedInUser({
            firstName: firstName,
            lastName: lastName,
            profileImg: avatar,
            uid: uid,
          });
          handleProfileClose();
        } else {
          setIsError(true);
          setMSG(result.data.message);
        }
      })
      .catch((e) => {
        switch (e.response?.status) {
          case 400:
          case 401:
            setAuthTokens(null);
            setSignedInUser(null);
        }
        const code = e.response.status;
        if (code === 401 || code === 403) {
          setAlertMsg(
            'ログイン期間が切れましたため、ログインしてからもう一度操作してください。'
          );
          setAlertHandler('logout');
          setOpenAlert(true);
        } else {
          setIsError(true);
          setMSG(e.message);
        }
      });
  };
  const handleUploadAvatar = (e) => {
    const uid = cur_user.uid;
    const formData = new FormData();
    formData.append('uid', uid);
    [...files].forEach((f) => formData.append('avatar', f, f.name));
    axios
      .patch(
        process.env.REACT_APP_AUTH_SERVER + 'files/upload-avatar',
        formData,
        {
          headers: {
            Authorization: `Bearer ${authTokens}`,
            ContentType: 'application/json',
          }
        }
      )
      .then((result) => {
        if (result.status === 200) {
          setSignedInUser({
            firstName: cur_user.firstName,
            lastName: cur_user.lastName,
            profileImg: result.data.profileImg,
            uid: uid,
          });
          setAvatar(result.data.profileImg);
          handleClose();
        } else {
          setIsError(true);
          setMSG(result.data.message);
        }
      })
      .catch((e) => {
        switch (e.response?.status) {
          case 400:
          case 401:
            setAuthTokens(null);
            setSignedInUser(null);
        }
        setIsError(true);
        setMSG(e.message);
      });
  };

  const openModelProperties = () => {
    window.open(modelPDF, '_blank');
  };

  const openPredictExcel = () => {
    setOpenExcel(true);
  };

  const handlePredictClose = () => {
    setOpenExcel(false);
  };

  const openUserManagement = () => {
    setOpenUser(true);
  };

  const handleUserManagementClose = () => {
    setOpenUser(false);
  };


  //日付の0埋め
  const toDoubleDigits = function(num) {
    num += "";
    if (num.length === 1) {
      num = "0" + num;
    }
   return num;
  };

  // フォーマット変更
  const date_format_change_hours = (date) => {
    let hour_str   = toDoubleDigits(date.getHours());
    let minute_str = toDoubleDigits(date.getMinutes());

    let format_str = 'hh:mm';
    format_str = format_str.replace(/hh/g, hour_str);
    format_str = format_str.replace(/mm/g, minute_str);

    return format_str
  }

  const login_show_bar = () => {
    let login_time = ''
    let local_loginTime = localStorage.getItem('loginTime');
    if(local_loginTime == null){
      login_time = new Date();
    }
    else{
      login_time = new Date(local_loginTime);
    }
    let formatChange_loginTime = date_format_change_hours(login_time);

    return formatChange_loginTime
  }

  useEffect(() => {
    setLoginTime(login_show_bar);
    axios.get(process.env.REACT_APP_AUTH_SERVER+'auth/user/?user_id=' + localStorage.getItem('currentUserId'),
        {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('userAuthToken')}`,
        }
      }).then((res)=> {
        setCurrentSimei(res.data.user.simei);
        setUserAdminiFlag(res.data.user.kanrisya_flg);
      }).catch((error) => {
        console.log(error);
        switch (error.response?.status) {
          case 400:
          case 401:
            setAuthTokens(null);
            setSignedInUser(null);
        }
      })
  },[])

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem component='label'>
        アバター変更
        <input
          accept='image/*'
          type='file'
          multiple
          style={{ display: 'none' }}
          onChange={(e) => {
            handleCapture(e);
          }}
        />
      </MenuItem>
      {imgFiles.map((f) => (
        <div className={classes.avatarRoot} key={f.title}>
          <Avatar alt={f.title} src={f.img} className={classes.large} />
          <Button onClick={handleUploadAvatar} color='primary'>
            更新
          </Button>
        </div>
      ))}
      {/* {isError && <Error>{msg}</Error>} */}
      <MenuItem onClick={handleProfileOpen}>個人情報変更</MenuItem>
      <MenuItem onClick={handleLogoutClick}>ログアウト</MenuItem>
      <MenuItem
        onClick={() => {
          setAlertMsg('退会を実行したら、戻せません。退会しますか?');
          setAlertHandler('delusr');
          setOpenAlert(true);
        }}
      >
        退会
      </MenuItem>
      <Dialog
        open={openAlert}
        onClose={() => {
          setOpenAlert(false);
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'アラート確認'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {alertMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmAlert} color='primary'>
            はい
          </Button>
          <Button
            onClick={() => {
              setOpenAlert(false);
            }}
            color='primary'
            autoFocus
          >
            いいえ
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openProfile}
        onClose={handleProfileClose}
        aria-labelledby='customized-dialog-title'
      >
        <DialogTitle id='customized-dialog-title'>ユーザー情報編集</DialogTitle>
        <DialogContent>
          <Form>
            <Input
              type='text'
              placeholder='firstName'
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
            <Input
              type='text'
              placeholder='lastName'
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </Form>
          {isError && <Error>{msg}</Error>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleProfileClose} color='primary'>
            キャンセル
          </Button>
          <Button onClick={handlePost} color='primary'>
            更新
          </Button>
        </DialogActions>
      </Dialog>
    </Menu>
  );
  const handleClickdataSet = (event) => {
    // console.log('map1:', map)
  }
  const renderLSidebar = (
    <Drawer
      className={classes.drawer}
      variant='persistent'
      anchor='left'
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <img src="logo.png" alt="logo" className={classes.logo} />
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? (
            <ChevronLeftIcon />
          ) : (
              <ChevronRightIcon />
            )}
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem button key='AIPrediction'>
          <ListItemIcon>
            <TrendingUpIcon />
          </ListItemIcon>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={openPredictExcel}>
            一括予測
          </Button>
        </ListItem>
        <ListItem button key='model'>
          <ListItemIcon>
            <ViewModuleIcon />
          </ListItemIcon>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={openModelProperties}>
            モデル特性
          </Button>
        </ListItem>
        <ListItem button key='userManagement'>
          <ListItemIcon>
            <TrendingUpIcon />
          </ListItemIcon>
          { user_admini_flag ?
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={openUserManagement}>
              ユーザー管理
            </Button>
            :
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={openUserManagement} disabled>
              ユーザー管理
            </Button>
          }
        </ListItem>
        <ListItem button key='logout'>
          <ListItemIcon>
            <ViewModuleIcon />
          </ListItemIcon>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleLogoutClick}>
            ログアウト
          </Button>
        </ListItem>
      </List>
    </Drawer>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label='account of current user'
          aria-controls='primary-search-account-menu'
          aria-haspopup='true'
          color='inherit'
          className={classes.avatarRoot}
        >
          <Avatar
            classes={{ root: classes.avatarRoot }}
            alt={current_user_simei}
            src={avatar}
          // className={classes.small}
          >
            {current_user_simei}
          </Avatar>
        </IconButton>
        <p onClick={handleLogoutClick}>ログアウト</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          {isBrowser &&
            <IconButton
              edge='start'
              className={clsx(classes.menuButton, open && classes.hide)}
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          }
          <Typography className={classes.title} variant='h6' noWrap>
            {process.env.REACT_APP_TITLE}
          </Typography>
          <SearchBar
            onPlaceLoaded={onPlaceLoaded}
            google={google}
            classes={classes}
          />
          <div className={classes.grow} />
          <Typography className={classes.title} variant='h6' noWrap style={{fontSize: '18px'}}>
            ユーザー名：{current_user_simei}
          </Typography>
          <Typography className={classes.title} variant='h6' noWrap style={{fontSize: '18px'}}>
            {"　ログイン時間："}{loginTime}
          </Typography>
          {/*
          <div className={classes.sectionDesktop}>
            <IconButton
              edge='end'
              aria-label='account of current user'
              aria-controls={menuId}
              aria-haspopup='true'
              onClick={handleProfileMenuOpen}
              color='inherit'
            >
              <Avatar
                classes={{ root: classes.avatarRoot }}
                alt={cur_user.firstName + ' ' + cur_user.lastName}
                src={avatar}
              >
                {cur_user.firstName}
              </Avatar>
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label='show more'
              aria-controls={mobileMenuId}
              aria-haspopup='true'
              onClick={handleMobileMenuOpen}
              color='inherit'
            >
              <MoreIcon />
            </IconButton>
          </div>
          */}
        </Toolbar>
      </AppBar>
      {/*renderMobileMenu*/}
      {/*renderMenu*/}
      {isBrowser && renderLSidebar}
      <PredExcel
        open={openExcel}
        onClose={handlePredictClose}
        google={google}
        map={map}
      />
      <UserManagement
        open={openUser}
        onClose={handleUserManagementClose}
      />
    </div>
  );

  function confirmAlert() {
    if (alertHandler === 'logout') {
      handleLogoutClick();
    } else if (alertHandler === 'delusr') {
      const uid = cur_user.uid;
      const token = authTokens;
      axios
        .delete(process.env.REACT_APP_AUTH_SERVER + 'users/delete', {
          data: { uid, token },
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens}`,
            ContentType: 'application/json',
          }
        })
        .then((result) => {
          if (result.status === 200) {
            handleProfileClose();
            setSignedInUser(null);
            setAuthTokens(null);
          } else {
            setIsError(true);
            setMSG(result.data.message);
          }
        })
        .catch((e) => {
          switch (e.response?.status) {
            case 400:
            case 401:
              setAuthTokens(null);
              setSignedInUser(null);
          }
          setIsError(true);
          setMSG(e.message);
        });
      setOpenAlert(false);
    }
  }
}
