import React from 'react';
import MapContainer from '../components/MapContainer';
import { AuthContext } from '../context/auth';
import { UserContext } from '../context/user';

function Home(props) {
  return (
      <AuthContext.Consumer>
        {auth => (
          <UserContext.Consumer>
            {user => (
              <MapContainer auth={auth} user={user} />
            )}
          </UserContext.Consumer>
        )}
      </AuthContext.Consumer>
    );
}

export default Home;
