import React, { useEffect, useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import axios from 'axios';
import { useAuth } from '../context/auth';
import { useUser } from '../context/user';

const useStyles = makeStyles((theme) => ({
  dialogContentRoot: {
    padding: '10px 24px  0px 24px',
  },
  dialogPaper: {
    width: '600px',
    height: '610px',
    minHeight: '610px'
  },
  closeButtonPadding: {
    padding: '15px 10px 12px 0px',
  },
  dialogButton: {
    fontWeight: 'normal',
    height: '30px',
    padding: '3px 3px',
  },
  EditButton: {
    fontWeight: 'normal',
    height: '30px'
  },
  dialogButtonString: {
    fontSize: '0.875rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
  },
  inputGroupTitle: {
    textAlign: 'center',
    height: '30px',
    padding: '5px',
    color: 'gray',
    border: 'solid 1px darkgrey',
    whiteSpace: 'nowrap',
  },
  inputGroupCell: {
    textAlign: 'center',
    height: '30px',
    padding: '2px 3px',
    color: 'gray',
    border: 'solid 1px darkgrey',
  },
  th1: {
    fontSize: '14px',
    color: 'gray',
    background: 'lightgray',
    fontWeight: 'lighter',
    textDecoration: 'none',
    border: 'solid 1px darkgrey',
  },
  th2: {
    border: 'none',
    height: '5px',
  },
  th3: {
    border: 'none',
    height: '15px',
  },
  fileNameCell: {
    fontSize: '14px',
    padding: '0px 3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    height: '30px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  textField: {
    borderRadius: '0',
  },
  inputWriteUser: {
    fontSize: '14px',
    padding: '0px 2px',
    border: 'solid 1px lightgrey',
    height: '25px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  }
}));

// フォーマット変更
const date_format_change = (date) => {
    let year_str = date.getFullYear();
    // 月だけ+1すること
    let month_str  = 1 + date.getMonth();
    let day_str    = date.getDate();
    let hour_str   = date.getHours();
    let minute_str = date.getMinutes();
    let second_str = date.getSeconds();

    let format_str = 'YYYY/MM/DD hh:mm:ss';
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    format_str = format_str.replace(/hh/g, hour_str);
    format_str = format_str.replace(/mm/g, minute_str);
    format_str = format_str.replace(/ss/g, second_str);

    return format_str
}

// 現在時刻取得
const date_get_today = () =>{
    var date = new Date();
    // フォーマット変更
    var r_format_date = date_format_change(date);
    return r_format_date
}

//文字列の昇順でソート
const  sorting_asc = (a, b) => {
    if(a[0] < b[0]){
        return -1;
    }else if(a[0] > b[0] ){
        return 1;
    }else{
        return 0;
    }
}

//バイト数の取得
String.prototype.bytes = function () {
    return(encodeURIComponent(this).replace(/%../g,"x").length);
}

// 氏名の判定
const simei_validation = (currentSimei, setSimeiErrorFlag) => {
    let error_simei_text = ''
    // 20バイト以下
    let byte_flag = (currentSimei.bytes() <= 20)
    // パスワード未入力 判定
    if(currentSimei.length == 0){
        error_simei_text = '氏名を入力してください。';
        setSimeiErrorFlag(true);
        return [false, error_simei_text];
    }
    if(!byte_flag){
        error_simei_text = '氏名は20バイト以下で入力してください。';
        setSimeiErrorFlag(true);
        return [false, error_simei_text];
    }
    else{
        setSimeiErrorFlag(false);
        return [true, error_simei_text];
    }
}

// エラーメッセージの表示
const alert_error_message = (first_form_flag, second_form_flag, first_form_message, second_form_message) => {
    let alert_error_message = '';
    if(!first_form_flag && second_form_flag){
        alert_error_message = first_form_message;
    }
    else if(first_form_flag && !second_form_flag){
        alert_error_message = second_form_message;
    }
    else{
        alert_error_message = first_form_message + '\n' + second_form_message;
    }
    alert(alert_error_message);
}


// 編集・更新用
function UserManagementRow(props) {
    const classes = useStyles();
    const [isboolen, setBoolen] = useState(false);
    const [adminiFlag,   setAdminiFlag]    = useState(false);
    const [invalidFlag,  setInvalidFlag]   = useState(false);
    const [currentSimei, setCurrentSimei]  = useState(props.input_lst[2]);
    const [passwordFormClickFlag, setPasswordFormClickFlag] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const ShowInsteadPassword = '●●●●●●●●●●●'
    const [passwordErrorFlag, setPasswordErrorFlag] = useState(false);
    const [simeiErrorFlag, setSimeiErrorFlag] = useState(false);
    const { setAuthTokens } = useAuth();
    const { setSignedInUser } = useUser();

    // リセット
    const handle_reset_row = () => {
        // チェックボックスフラグ整理 : 管理者
        if (props.input_lst[3] != '0'){
            setAdminiFlag(true);
        }
        else{
            setAdminiFlag(false);
        }
        // チェックボックスフラグ整理 : 無効
        if (props.input_lst[4] != '0'){
            setInvalidFlag(true);
        }
        else{
            setInvalidFlag(false);
        }
        setCurrentPassword('');
        setCurrentSimei(props.input_lst[2]);

        // errorの初期化
        setSimeiErrorFlag(false);
        setPasswordErrorFlag(false);
        // passwordFormClickの初期化
        setPasswordFormClickFlag(false);

    }

    useEffect(() => {
        handle_reset_row();
      },[props.input_lst[3], props.input_lst[4]])

    // パスワードの判定
    const password_validation = () => {
        let error_message = ''
        // 20バイト以下
        let byte_flag = (currentPassword.bytes() <= 20)
        // パスワード未入力 判定
        if(currentPassword.length == 0){
            error_message = 'パスワードを入力してください。';
            setPasswordErrorFlag(true);
            return [false, error_message];
        }
        // 半角英数字 (8以上14以下)
        let regex = /^([a-zA-Z0-9]{8,14})$/;
        // 半角数字のみ
        let number_regex = /^[0-9]+$/
        // 半角英字のみ
        let alphabet_regex = /^[a-zA-Z]+$/

        // 半角英数字 判定
        if(!regex.test(currentPassword) || number_regex.test(currentPassword) || alphabet_regex.test(currentPassword) || !byte_flag){
            error_message = 'パスワードは半角英数混在かつ8～14文字の範囲で入力してください。';
            setPasswordErrorFlag(true);
            return [false, error_message];
        }
        else{
            setPasswordErrorFlag(false);
            return [true, error_message];
        }
    }

    // 更新ボタン 氏名空欄のエラーメッセージの表示
    const alert_simei_blanck_error_message = (simei_error_text) => {
        alert(simei_error_text);
        // errorの初期化
        setPasswordErrorFlag(false);
    }

    // 更新
    const handle_update_click = () => {
        let password_return_lst = password_validation();
        let simei_return_lst    = simei_validation(currentSimei, setSimeiErrorFlag);
        let password_flag       = password_return_lst[0];
        let password_error_text = password_return_lst[1];
        let simei_flag          = simei_return_lst[0];
        let simei_error_text    = simei_return_lst[1];
        if(password_flag && simei_flag || !passwordFormClickFlag){
            if(simei_flag){
                let deep_all_input_lst = JSON.parse(JSON.stringify(props.all_input_lst));
                let admini_flag_value  = (adminiFlag  ? 1:0);
                let invalid_flag_value = (invalidFlag ? 1:0);
                // 更新日時
                let update_date = date_get_today();
                deep_all_input_lst[props.index] = [props.input_lst[0], currentPassword, currentSimei, admini_flag_value, invalid_flag_value]
                let ascending = deep_all_input_lst.sort(sorting_asc);
                props.setInputlst(ascending);
                // フラグを戻す。
                edit_flag_set_handle(false);

                let update_user_data = '';

                // パスワード変更がない場合はパスワードを入力値に入れない。
                if(passwordFormClickFlag){
                    update_user_data = {
                        user_id: props.input_lst[0],
                        password:currentPassword,
                        simei:   currentSimei,
                        kanrisya_flg: admini_flag_value,
                        mukou_flg:    invalid_flag_value,
                        update_user_id: localStorage.getItem('currentUserId'),
                        update_date: update_date
                    }
                }
                else{
                    update_user_data = {
                        user_id: props.input_lst[0],
                        simei:   currentSimei,
                        kanrisya_flg: admini_flag_value,
                        mukou_flg:    invalid_flag_value,
                        update_user_id: localStorage.getItem('currentUserId'),
                        update_date: update_date
                    }
                }
                //　DB登録　
                axios.request({
                method: 'put',
                url: process.env.REACT_APP_AUTH_SERVER+'auth/update',
                data: update_user_data,
                headers:{Authorization: `Bearer ${props.token}`}
                })
                // passwordFormClickの初期化
                setPasswordFormClickFlag(false);
            }
            else{
                // 氏名空欄の時にアラートを出す。
                alert_simei_blanck_error_message(simei_error_text);
            }
        }
        else{
            alert_error_message(password_flag, simei_flag, password_error_text, simei_error_text);
        }
    }

    // 編集フラグ管理
    const edit_flag_set_handle = (isEdit) => {
        let syoki_flag_lst = Array(props.editOpenFlagAll.length).fill(false);
        if(isEdit){
            syoki_flag_lst[props.index] = true
        }
        props.setEditOpenFlagAll(syoki_flag_lst);
        //強制レンダリング
        setBoolen(!isboolen);
    }

    // 編集
    const handle_edit_click = () => {
        edit_flag_set_handle(true);
    }
    // キャンセル
    const handle_cancel_click = () => {
        handle_reset_row();
        edit_flag_set_handle(false);
    }

    const handleAdminiCheck = () => {
        setAdminiFlag(!adminiFlag);
    }

    const handleInvalidCheck = () => {
        setInvalidFlag(!invalidFlag)
    }

    // パスワードフォームクリック時の処理
    const password_form_click_handle = () => {
        if(!passwordFormClickFlag){
            setCurrentPassword('');
        }
        setPasswordFormClickFlag(true);
    }

    // パスワードフォーム入力時の処理
    const password_form_change_handle = (event) => {
        setPasswordFormClickFlag(true);
        setCurrentPassword(event.target.value);
    }

    // 初期値だけ見せかけのパスワードを見せる。
    const syoki_value = () => {
        if(passwordFormClickFlag){
            return currentPassword
        }
        else{
            return '●●●●●●●●'
        }
    }

    return (
        <TableRow className={classes.th2}>
            <TableCell className={classes.inputGroupCell}  style={{width: '44px'}}>
                {props.editOpenFlagAll[props.index] ?
                <div>
                    <Button
                        component="label"
                        variant='contained'
                        className={classes.EditButton}
                        classes={{label: classes.dialogButtonString}}
                        onClick={(() => handle_update_click())}
                    >
                        更新
                    </Button>
                    <div style={{padding: '3px'}}></div>
                    <Button
                        style={{padding: '3px'}}
                        component="label"
                        variant='contained'
                        className={classes.EditButton}
                        classes={{label: classes.dialogButtonString}}
                        onClick={(() => handle_cancel_click())}
                    >
                        ｷｬﾝｾﾙ
                    </Button>
                </div>
                :
                <div>
                    <Button
                        component="label"
                        variant='contained'
                        className={classes.EditButton}
                        classes={{label: classes.dialogButtonString}}
                        onClick={() => handle_edit_click()}
                    >
                        編集
                    </Button>
                </div>
                }
            </TableCell>
            <TableCell className={classes.fileNameCell}>
                {props.input_lst[0]}
            </TableCell>
            <TableCell  className={classes.fileNameCell} style={{width: '30px', padding: '0px 1px'}}>
                {props.editOpenFlagAll[props.index] ?
                    <>
                        <TextField
                            inputProps={{style: {fontSize: '14px', padding: '2px 1px', paddingLeft: '3px'}}}
                            InputProps={{classes: {root: classes.textField}}}
                            margin='none'
                            variant="outlined"
                            name='new_password_form'
                            style={{width: '100%', height: '22px', justifyContent: 'center'}}
                            onClick={ ()=> {password_form_click_handle();}}
                            onChange={(event) => password_form_change_handle(event)}
                            value={syoki_value()}
                            autoComplete='new-password'
                            type="password"
                            error={passwordErrorFlag}
                        />
                    </>
                :
                    ShowInsteadPassword
                }
            </TableCell>
            <TableCell className={classes.fileNameCell} style={{padding: '0px 1px'}}>
                {props.editOpenFlagAll[props.index] ?
                <>
                    <TextField
                        inputProps={{style: {fontSize: '14px', padding: '2px 1px', paddingLeft: '3px'}}}
                        InputProps={{classes: {root: classes.textField}}}
                        margin='none'
                        variant="outlined"
                        style={{width: '100%', height: '22px', justifyContent: 'center'}}
                        onChange={(event) => setCurrentSimei(event.target.value)}
                        value={currentSimei}
                        error={simeiErrorFlag}
                    />
                </>
                :
                    props.input_lst[2]
                }
            </TableCell>
            <TableCell className={classes.fileNameCell} align='center'>
                <Checkbox
                    color='primary'
                    size="small"
                    checked={adminiFlag}
                    onChange={handleAdminiCheck}
                    disabled={!props.editOpenFlagAll[props.index]}
                />
            </TableCell>
            <TableCell className={classes.fileNameCell} align='center'>
                <Checkbox
                    color='primary'
                    size="small"
                    checked={invalidFlag}
                    onChange={handleInvalidCheck}
                    disabled={!props.editOpenFlagAll[props.index]}
                />
            </TableCell>
        </TableRow>
    );
}

//　登録
function UserManagementRegister(props) {
    const classes = useStyles();
    const [currentUserId, setCurrentUserId] = useState('');
    const [currentSimei,  setCurrentSimei]  = useState('');
    const [adminiFlag,    setAdminiFlag]    = useState(false);
    const [invalidFlag,   setInvalidFlag]   = useState(false);
    const [userIdErrorFlag, setUserIdErrorFlag] = useState(false);
    const [simeiErrorFlag, setSimeiErrorFlag] = useState(false);
    const { setAuthTokens } = useAuth();
    const { setSignedInUser } = useUser();

    // リセット
    const handle_reset = () => {
        setCurrentUserId('');
        setCurrentSimei('');
        setAdminiFlag(false);
        setInvalidFlag(false);
    }

    // ユーザーIDの判定
    const userId_validation = () => {
        let error_message = ''
        // ユーザーID未入力 判定
        if(currentUserId.length == 0){
            setUserIdErrorFlag(true);
            error_message = 'ユーザーIDを入力してください。'
            return [false, error_message];
        }
        //同じID 判定
        let userId_same_flag = false;
        props.input_lst.map((data, index) => {
            if(data[0] == currentUserId){
                userId_same_flag = true;
            }
        })
        if (userId_same_flag){
            error_message = 'このユーザーIDは既に登録されています。'
            setUserIdErrorFlag(true);
            return [false, error_message];
        }
        // 半角英数字 (8以上14以下)
        let regex = /^([a-zA-Z0-9]{8,14})$/;
        // 半角数字のみ
        let number_regex = /^[0-9]+$/
        // 半角英字のみ
        let alphabet_regex = /^[a-zA-Z]+$/

        // 半角英数字 判定
        if(!regex.test(currentUserId) || number_regex.test(currentUserId) || alphabet_regex.test(currentUserId)){
            error_message = 'ユーザーIDは半角英数混在かつ8～14文字の範囲で入力してください。'
            setUserIdErrorFlag(true);
            return [false, error_message];
        }
        else{
            setUserIdErrorFlag(false);
            return [true, error_message];
        }
    }

    // 登録
    const handle_register_click = () => {
        let userId_return_lst = userId_validation();
        let simei_return_lst  = simei_validation(currentSimei, setSimeiErrorFlag);
        let userId_flag       = userId_return_lst[0];
        let userId_error_text = userId_return_lst[1];
        let simei_flag        = simei_return_lst[0];
        let simei_error_text  = simei_return_lst[1];

        if (userId_flag && simei_flag){
            let arr = JSON.parse(JSON.stringify(props.input_lst));
            let admini_flag_value  = (adminiFlag  ? 1:0);
            let invalid_flag_value = (invalidFlag ? 1:0);
            // 登録日時
            let register_date = date_get_today();
            // 登録userData
            const register_user_data = {
                user_id: currentUserId,
                simei:   currentSimei,
                kanrisya_flg: admini_flag_value,
                mukou_flg:    invalid_flag_value,
                create_user_id: localStorage.getItem('currentUserId'),
                create_date:    register_date,
                update_user_id: localStorage.getItem('currentUserId'),
                update_date: register_date
            }
            //　DB登録　
            axios.request({
            method: 'post',
            url: process.env.REACT_APP_AUTH_SERVER+'auth/register',
            data: register_user_data,
            headers:{Authorization: `Bearer ${props.token}`}
          }).then((result) => {

          }).catch((error) => {
            switch (error.response?.status) {
                case 400:
                case 401:
                    setAuthTokens(null);
                    setSignedInUser(null);
            }
          })
            arr.push([currentUserId, 'pw123456', currentSimei, admini_flag_value, invalid_flag_value])
            var ascending = arr.sort(sorting_asc);
            props.setInputlst(ascending);
            // リセット
            handle_reset();
        }
        else{
            alert_error_message(userId_flag, simei_flag, userId_error_text, simei_error_text);
        }
    }

    const handleAdminiCheck = () => {
        setAdminiFlag(!adminiFlag);
    }

    const handleInvalidCheck = () => {
        setInvalidFlag(!invalidFlag);
    }

    return (
        <TableRow className={classes.th2}>
            <TableCell className={classes.inputGroupCell}  style={{width: '44px'}}>
                <Button
                    component="label"
                    variant='contained'
                    className={classes.EditButton}
                    classes={{label: classes.dialogButtonString}}
                    onClick={() => handle_register_click()}
                >
                    登録
                </Button>
            </TableCell>
            <TableCell className={classes.inputWriteUser}>
                <div>
                    <TextField
                        inputProps={{style: {fontSize: '14px', padding: '2px 1px', paddingLeft: '3px'}}}
                        InputProps={{classes: {root: classes.textField}}}
                        margin='none'
                        variant="outlined"
                        style={{width: '100%', height: '22px', justifyContent: 'center'}}
                        onChange={(event) => setCurrentUserId(event.target.value)}
                        value={currentUserId}
                        error={userIdErrorFlag}
                    />
                </div>
            </TableCell>
            <TableCell className={classes.fileNameCell}>

            </TableCell>
            <TableCell className={classes.inputWriteUser}>
                <div>
                <TextField
                    inputProps={{style: {fontSize: '14px', padding: '2px 1px', paddingLeft: '3px'}}}
                    InputProps={{classes: {root: classes.textField}}}
                    margin='none'
                    variant="outlined"
                    style={{width: '100%', height: '22px', justifyContent: 'center'}}
                    onChange={(event) => setCurrentSimei(event.target.value)}
                    value={currentSimei}
                    error={simeiErrorFlag}
                />
                </div>
            </TableCell>
            <TableCell className={classes.fileNameCell} align='center'>
                <Checkbox color='primary'
                          size="small"
                          checked={adminiFlag}
                          onChange={handleAdminiCheck}
                />
            </TableCell>
            <TableCell className={classes.fileNameCell} align='center'>
                <Checkbox color='primary'
                          size="small"
                          checked={invalidFlag}
                          onChange={handleInvalidCheck}
                />
            </TableCell>
        </TableRow>
    )
  }

export default function UserManagement(props) {
  const classes = useStyles();
  const {
    open,
    onClose,
  } = props;

  const handleDialogClose = () => {
    setEditOpenFlagAll(Array(editOpenFlagAll.length).fill(false));
    onClose();
  };
  const [allInputlst, setAllInputlst] = useState([]);
  const userToken = localStorage.getItem('userAuthToken');
  const [editOpenFlagAll, setEditOpenFlagAll] = useState([]);
  const { setAuthTokens } = useAuth();
  const { setSignedInUser } = useUser();

  useEffect(() => {
    //ユーザーIDの昇順で変更
    var arr = JSON.parse(JSON.stringify(allInputlst))
    var ascending = arr.sort(sorting_asc);
    setAllInputlst(ascending);
    axios.get(process.env.REACT_APP_AUTH_SERVER+'auth/users',{
        headers: {
            Authorization: `Bearer ${userToken}`,
        }
        }).then((res)=> {
        let user_lst = []
        res.data.users.forEach((value) => {
            let tmp_user_lst = [value["user_id"], 'pw123456', value["simei"], value['kanrisya_flg'], value['mukou_flg']]
            user_lst.push(tmp_user_lst);
        })
        setAllInputlst(user_lst.sort(sorting_asc));
        setEditOpenFlagAll(Array(user_lst.length).fill(false));
        }).catch((error) => {
            switch (error.response?.status) {
                case 400:
                case 401:
                    setAuthTokens(null);
                    setSignedInUser(null);
            }
        })
  },[])

  return (
    <Dialog
      open={open}
      aria-labelledby='form-dialog-title'
      classes={{paper: classes.dialogPaper}}
    >
    <DialogTitle style={{padding: '0px'}}>
        <Grid container style={{background: 'lightgray', padding: '3px 13px'}}>
          <Typography variant='body1' component='div'>
             ユーザー管理
          </Typography>
        </Grid>
    </DialogTitle>
    <DialogActions classes={{root: classes.closeButtonPadding}}>
        <Button
            onClick={handleDialogClose}
            variant='contained'
            className={classes.dialogButton}
            classes={{label: classes.dialogButtonString}}
            style={{minWidth: '100px'}}
        >
            {'閉じる'}
        </Button>
    </DialogActions>
    <DialogContent dividers classes={{root: classes.dialogContentRoot}} style={{border: 'solid lightgray 1px' , margin:'0px 10px 30px 10px'}}>
            <Table>
              <TableHead >
                <TableRow className={classes.th1}>
                  <TableCell className={classes.inputGroupCell}></TableCell>
                  <TableCell className={classes.inputGroupTitle} style={{width: '100px'}}>ユーザーID</TableCell>
                  <TableCell className={classes.inputGroupTitle}>パスワード</TableCell>
                  <TableCell className={classes.inputGroupTitle} style={{width: '100px'}}>氏名</TableCell>
                  <TableCell className={classes.inputGroupTitle} style={{width: '50px'}}>管理者</TableCell>
                  <TableCell className={classes.inputGroupTitle} style={{width: '50px'}}>無効</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                    {allInputlst.map((data, index) => {
                        return(
                        <UserManagementRow
                            input_lst={data}
                            key={data}
                            index={index}
                            all_input_lst={allInputlst}
                            setInputlst={setAllInputlst}
                            token={userToken}
                            editOpenFlagAll={editOpenFlagAll}
                            setEditOpenFlagAll={setEditOpenFlagAll}/>
                        )
                    })}
                    <UserManagementRegister
                        input_lst={allInputlst}
                        setInputlst={setAllInputlst}
                        token={userToken}/>
              </TableBody>
            </Table>
    </DialogContent>
    </Dialog>
  );
}