import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { Card, Logo, Form, Input, Button, Error } from '../components/AuthForm';
import SimpleBackdrop from '../components/CirProgress';

const link = process.env.REACT_APP_RESET_LINK;
function ForgetPwd(props) {
  const [hasSent, setHasSent] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showProgBar, setShowProgBar] = useState(false);
  const [email, setUserName] = useState('');

  function postForgetPwd() {
    setShowProgBar(true);
    axios
      .post(process.env.REACT_APP_AUTH_SERVER + 'users/forget-pwd', {
        email,
        link,
      })
      .then((result) => {
        if (result.data.result === 'Success') {
          setHasSent(true);
        } else {
          setIsError(true);
        }
        setShowProgBar(false);
      })
      .catch((e) => {
        setIsError(true);
        setShowProgBar(false);
      });
  }

  const referer =
    props.location.state !== undefined ? props.location.state.referer : '/';

  if (hasSent) {
    return <Redirect to={referer} />;
  }

  return (
    <div>
      <SimpleBackdrop
        open={showProgBar}
      />
      <Card>
        <Logo />
        <Form>
          <Input
            type='username'
            value={email}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            placeholder='email'
          />
          <Button onClick={postForgetPwd}>発信</Button>
        </Form>
        <Link to='/login'>ログインに戻る</Link>
        {isError && (
          <Error>The username or password provided were incorrect!</Error>
        )}
      </Card>
    </div>
  );
}

export default ForgetPwd;
