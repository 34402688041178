import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import xlsx from 'xlsx';
import Excel from 'exceljs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import { between } from '../utils/ComputeDistance';
import { getGeocode } from '../utils/GetGeocode';
import { getNearbySearch } from '../utils/GetNearbySearch';
import Alert from './ErrorAlert';
import CircularProgress from './CircularProgressWrapper';
import { useAuth } from '../context/auth';
import { useUser } from '../context/user';

const useStyles = makeStyles(() => ({
  dialogContentRoot: {
    padding: '8px 8px 24px',
  },
  dialogPaper: {
    width: '450px',
  },
  dialogButton: {
    fontWeight: 'normal',
    height: '30px',
    width: '100px',
    padding: '3px 13px',
  },
  dialogButtonString: {
    fontSize: '0.875rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
  },
  inputGroupTitle: {
    textAlign: 'center',
    height: '30px',
    padding: '0px',
    color: 'gray',
    borderBottom: 'solid 1px darkgrey',
  },
  th1: {
    fontSize: '14px',
    color: 'gray',
    background: 'lightgray',
    fontWeight: 'lighter',
    textDecoration: 'underline',
    border: 'solid 1px darkgrey',
  },
  th2: {
    border: 'none',
    height: '10px',
  },
  closeButtonPadding: {
    padding: '8px',
  },
  itemNameCell: {
    fontSize: '10pt',
    padding: '3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    width: '25%',
    background: 'rgb(240, 238, 238)',
    height: '25px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  itemValueCell: {
    padding: '3px',
    color: 'gray',
    border: 'solid 1px lightgrey',
    height: '25px',
  },
  radio: {
    paddingRight: '4px',
  },
  radioLabel: {
    color: 'gray',
    fontSize: '11pt',
  },
  formControlLabel: {
    height: '25px',
  },
  formControl: {
    left: '10px',
    height: '25px',
  },
  input: {
    display: 'none',
  },
  progressDialog: {
    height: '200px',
    width: '200px',
  }
}));

export default function PredExcel(props) {
  const classes = useStyles();
  const {
    open,
    onClose,
    google,
    map,
  } = props;

  const [inputType, setInputType] = useState('simple');
  const [shopInputs, setShopInputs] = useState([]);
  const [progress, setProgress] = useState(false);
  const [excelFile, setExcelFile] = useState();
  const [visibleRow, setVisibleRow] = useState([]);
  const [filename, setFilename] = useState('');
  const [errOpen, setErrOpen] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [errOpenExecution, setErrOpenExecution] = useState(false);
  const [errMsgExecution, setErrMsgExecution] = useState('');
  const ShopTypes = ['restaurant', 'supermarket', 'home_goods_store', 'drugstore'];
  const [loadingCount, setLoadingCount] = useState('');
  const [totalpredictCount, setTotalPredictCount] = useState('');
  const { authTokens, setAuthTokens } = useAuth();
  const { setSignedInUser } = useUser();

  const handleInputTypeChange = (event) => {
    setInputType(event.target.value);
  };

  const emptyRow = (row) => {
    let isEmpty = false;
    for (const key of Object.keys(row)) {
      isEmpty = !row[key];
    }
    return isEmpty;
  };

  const handleCrtPDF = (shop_name, pdf_data) => {
    return new Promise((resolve) => {
      document.getElementById('divtoPDF').hidden = false;
      const p1 = document.getElementById('p1');
      const p2 = document.getElementById('p2');
      const pdf = new jsPDF('p', 'pt', 'a4');
      html2canvas(p1, {
        useCORS: true,
        dpi: 144,
      }).then((canvas) => {
        let pageData = canvas.toDataURL('image/jpeg');
        let width = pdf.internal.pageSize.width;
        let height = pdf.internal.pageSize.height;
        pdf.addImage(pageData, 'JPEG', 0, 20, width, 0);
        // pdf.setFontSize(10);
        // pdf.text(width / 2 - 7, height - 17, '1/2');
        pdf.addPage();

        html2canvas(p2, {
          useCORS: true,
          dpi: 144,
        }).then((canvas2) => {
          pageData = canvas2.toDataURL('image/jpeg');
          width = pdf.internal.pageSize.width;
          pdf.addImage(pageData, 'JPEG', 0, 20, width, 0);
          // pdf.setFontSize(10);
          // pdf.text(width / 2 - 7, height - 17, '2/2');
          let today = new Date();
          const dd = String(today.getDate()).padStart(2, '0');
          const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          const yyyy = today.getFullYear();
          const hh = today.getHours();
          const min = today.getMinutes();
          const ss = today.getSeconds();

          today = yyyy + mm + dd + '_' + hh + min + ss;
          // setTimeout(() => {
          pdf_data.push({pdf: pdf, shop_name: shop_name})
          // window.open(pdf.output('bloburl', { filename: 'predictionResult' + today + '.pdf' }));
          document.getElementById('divtoPDF').hidden = true;
          // }, 200);
          resolve();
        });
      });
    });
  };

  const readExcelFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = e => {
        resolve(e.target.result);
      }
      reader.readAsArrayBuffer(file);
    });
  };

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  };

  const fillEmptySlots = (arr, value = undefined) => {
    for (let i = 0; i < arr.length; i++) {
      if (!(i in arr)) {
        arr[i] = value;
      }
    }
  };

  const handleDialogClose = () => {
    setFilename('');
    setInputType('simple');
    setErrOpen(false);
    setErrMsg('');
    onClose();
  };

  const handleErrDialogClose = () => {
    setErrOpen(false);
    //errorメッセージの初期化
    setFilename('');
    setErrMsg('');
  }

  const handleErrDialogExecutionClose = () => {
    setErrOpenExecution(false);
    //errorメッセージの初期化
    setErrMsgExecution('');
  }

  // エクセルファイルの読み込みと書き込みで異なるライブラリを使用している
  // 読み込み Sheet.js(xlsx)
  // 書き込み ExcelJS
  // Sheet.jsは書式等の保存ができず、ExcelJSは不要な空白行の読み込みや開店時間、閉店時間の正しい読み込みが行えないため

  const handleUploadExcelFile = async (event) => {
    const file = event.target.files[0];
    setExcelFile(file);
    setFilename(file.name);
    event.target.value = '';

    const excelData = await readExcelFile(file);
    const workbook = xlsx.read(excelData, {cellStyles: true});
    const sheet_name_list = workbook.SheetNames;
    const worksheet = workbook.Sheets[sheet_name_list[0]];
    const worksheet_json = xlsx.utils.sheet_to_json(worksheet, {raw: false});
    const A1Value = worksheet['A1']?.w.replace(/\r?\n/g, '');
    if (inputType === 'simple' && A1Value !== '管理№') {
      setErrOpen(true);
      setErrMsg('簡易版のEXCELを取込んでください。');
      return
    } else if (inputType === 'full' && A1Value !== '登録日') {
      setErrOpen(true);
      setErrMsg('フル版のEXCELを取込んでください。');
      return
    }

    // 非表示行の確認
    // 行のスタイルが変更されている一番下の行までしか示されない
    const rowInfos = worksheet['!rows'];
    fillEmptySlots(rowInfos, {hidden: false});

    // 途中で切れている場合、残りの行は表示されていると判断
    while (rowInfos.length < worksheet_json.length + 1) {
      rowInfos.push({hidden: false});
    }
    const hiddenRow = rowInfos.map(rowInfo => rowInfo.hidden ? true : false);
    let visibleRowIndex = hiddenRow.map((row, index) => {
      if (!row) {
        return index;
      }
    }).filter((e, i) => e !== undefined);
    visibleRowIndex.shift();
    setVisibleRow(visibleRowIndex);

    const removeComma = (num) => {
      return num.replace(/,/g, '');
    }
    const parseInteger = (num) => {
      if (typeof num === 'undefined') {
        return null;
      }
      return parseInt(removeComma(num));
    }

    const parseNumber = (num) => {
      if (typeof num === 'undefined') {
        return null;
      }
      return Number(removeComma(num));
    }

    const convertStr = (str) => {
      if (typeof str === 'undefined') {
        return null;
      }
      return str;
    };

    const round = (num, digit) => {
      if (typeof num === 'undefined') {
        return null;
      }
      const digitVal = Math.pow(10, digit);
      return Math.round(Number(removeComma(num)) * digitVal) / digitVal;
    }

    const min = (a, b) => {
      if (a === null) {
        return null;
      }
      return Math.min(a, b);
    }

    const changePreviousBusinessConditions = (value) => {
      if (typeof value === 'undefined') {
        return null;
      }
      if (['その他', '不明', 'コンビニ', '飲食店', '更地'].includes(value)) {
        return value;
      } else {
        return 'その他';
      }
    };

    const shops = [];
    for (let i = 0; i < worksheet_json.length; i++) {
      const row = worksheet_json[i];
      if (hiddenRow[i + 1]) {
        continue;
      }
      if (inputType === 'simple') {
        if (emptyRow(row)) {
          continue;
        }
        const shop = {
          sub_category: convertStr(row[worksheet['G1'].v]),
          address: convertStr(row[worksheet['I1'].v]),
          business_hours: parseNumber(row[worksheet['K1'].v]),
          nearest_station: convertStr(row[worksheet['L1'].v]),
          distance_nearest_station: round(row[worksheet['M1'].v], 3),
          number_of_passengers_nearest_station: round(row[worksheet['N1'].v], 0),
          number_of_seats: round(row[worksheet['O1'].v], 0),
          number_of_parking: round(row[worksheet['P1'].v], 0),
          fill_parking: min(round(row[worksheet['Q1'].v], 3), 2),
          shared_parking: parseInteger(row[worksheet['R1'].v]),
          site_surface: parseInteger(row[worksheet['S1'].v]),
          cutting_down_the_number_of_connections: parseInteger(row[worksheet['T1'].v]),
          nearest_convenience_store: parseInteger(row[worksheet['U1'].v]),
          crossroad: convertStr(row[worksheet['V1'].v]),
          location_type: convertStr(row[worksheet['W1'].v]),
          shopping_complex: convertStr(row[worksheet['X1'].v]),
          main_frontage: round(row[worksheet['Y1'].v], 1),
          main_road_type: parseInteger(row[worksheet['Z1'].v]),
          main_separation_zone: parseInteger(row[worksheet['AA1'].v]),
          main_traffic_census: parseInteger(row[worksheet['AB1'].v]),
          main_total_number_of_lanes: parseInteger(row[worksheet['AC1'].v]),
          number_of_main_shop_side_lanes: parseInteger(row[worksheet['AD1'].v]),
          main_traffic_volume_per_lane: parseInteger(row[worksheet['AE1'].v]),
          sideroad1_frontage: round(row[worksheet['AF1'].v], 1),
          sideroad1_type: parseInteger(row[worksheet['AG1'].v]),
        };
        shops.push(shop);
      } else {
        if (emptyRow(row)) {
          continue;
        }
        const shop = {
          gyotai: convertStr(row[worksheet['B1'].v]),
          shop_name: convertStr(row[worksheet['C1'].v]),
          category: convertStr(row[worksheet['E1'].v]),
          sub_category: convertStr(row[worksheet['F1'].v]),
          sub_category: convertStr(row[worksheet['F1'].v]),
          address: convertStr(row[worksheet['H1'].v]),
          opening_hours: convertStr(row[worksheet['J1'].w]),
          closing_hours: convertStr(row[worksheet['K1'].w]),
          business_hours: parseNumber(row[worksheet['L1'].v]),
          nearest_station: convertStr(row[worksheet['M1'].v]),
          distance_nearest_station: round(row[worksheet['N1'].v], 3),
          number_of_passengers_nearest_station: round(row[worksheet['O1'].v], 0),
          number_of_seats: round(row[worksheet['P1'].v], 0),
          number_of_parking: round(row[worksheet['Q1'].v], 0),
          shared_parking: parseInteger(row[worksheet['R1'].v]),
          fill_parking: min(round(row[worksheet['S1'].v], 3), 2),
          site_surface: parseInteger(row[worksheet['T1'].v]),
          cutting_down_the_number_of_connections: parseInteger(row[worksheet['U1'].v]),
          nearest_convenience_store: parseInteger(row[worksheet['V1'].v]),
          crossroad: convertStr(row[worksheet['W1'].v]),
          location_type: convertStr(row[worksheet['X1'].v]),
          shopping_complex: convertStr(row[worksheet['Y1'].v]),
          number_of_total_complex: parseInteger(row[worksheet['Z1'].v]),
          number_of_complex_restaurant: parseInteger(row[worksheet['AA1'].v]),
          number_of_complex_other: parseInteger(row[worksheet['AB1'].v]),
          cut_off_position: convertStr(row[worksheet['AC1'].v]),
          main_frontage: round(row[worksheet['AD1'].v], 1),
          main_road_type: parseInteger(row[worksheet['AE1'].v]),
          main_separation_zone: parseInteger(row[worksheet['AF1'].v]),
          main_traffic_census: parseInteger(row[worksheet['AG1'].v]),
          main_total_number_of_lanes: parseInteger(row[worksheet['AH1'].v]),
          number_of_main_shop_side_lanes: parseInteger(row[worksheet['AI1'].v]),
          measured_main_traffic_volume: parseInteger(row[worksheet['AJ1'].v]),
          main_traffic_volume_per_lane: parseInteger(row[worksheet['AK1'].v]),
          main_effective_number_of_cars: parseInteger(row[worksheet['AL1'].v]),
          total_number_of_main_cutoffs: parseInteger(row[worksheet['AM1'].v]),
          main_road_width_including_opposite_lane: round(row[worksheet['AN1'].v], 1),
          main_median_type: convertStr(row[worksheet['AO1'].v]),
          main_sidewalk_availability: convertStr(row[worksheet['AP1'].v]),
          crosswalk_in_front_of_main_store: convertStr(row[worksheet['AQ1'].v]),
          bus_stop_within_50m: convertStr(row[worksheet['AR1'].v]),
          highway_entrance_distance: convertStr(row[worksheet['AS1'].v]),
          highway_exit_distance: convertStr(row[worksheet['AT1'].v]),
          main_approach: parseInteger(row[worksheet['AU1'].v]),
          main_poles_wires: convertStr(row[worksheet['AV1'].v]),
          main_street_tree: convertStr(row[worksheet['AW1'].v]),
          sideroad1_deviation: convertStr(row[worksheet['AX1'].v]),
          sideroad1_frontage: round(row[worksheet['AY1'].v], 1),
          sideroad1_type: parseInteger(row[worksheet['AZ1'].v]),
          sideroad1_separation_zone: parseInteger(row[worksheet['BA1'].v]),
          sideroad1_total_number_of_lanes: parseInteger(row[worksheet['BB1'].v]),
          sideroad1_number_of_lanes_shop_side: parseInteger(row[worksheet['BC1'].v]),
          sideroad1_traffic_census: parseInteger(row[worksheet['BD1'].v]),
          sideroad1_traffic_actual_measured: parseInteger(row[worksheet['BE1'].v]),
          sideroad1_traffic_per_lane: parseInteger(row[worksheet['BF1'].v]),
          sideroad1_number_of_cars_effective: parseInteger(row[worksheet['BG1'].v]),
          total_number_of_all_side_road_cutoffs: parseInteger(row[worksheet['BH1'].v]),
          sideroad1_road_width: round(row[worksheet['BI1'].v], 1),
          sideroad1_median: convertStr(row[worksheet['BJ1'].v]),
          sideroad1_sidewalk: convertStr(row[worksheet['BK1'].v]),
          sideroad1_crosswalk: convertStr(row[worksheet['BL1'].v]),
          guardrail_main_store: parseInteger(row[worksheet['BM1'].v]),
          jam_strength: parseInteger(row[worksheet['BN1'].v]),
          car_speed: parseInteger(row[worksheet['BO1'].v]),
          visibility: parseInteger(row[worksheet['BP1'].v]),
          previous_business_conditions: changePreviousBusinessConditions(row[worksheet['BQ1'].v]),
          new_built_or_furnish: convertStr(row[worksheet['BR1'].v]),
          prepared_foods: parseInteger(row[worksheet['BS1'].v]),
        };
        shops.push(shop);
      }
    }
    setShopInputs(shops);
    console.log(shops);
  };

  const handlePrediction = async () => {
    if (filename === ''){
      setErrOpenExecution(true);
      setErrMsgExecution('EXCELを取り込んでください。')
    }
    else {
      const excelData = await readExcelFile(excelFile);
      const workbook = new Excel.Workbook();
      await workbook.xlsx.load(excelData);
      const worksheet = workbook.worksheets[0];
      setProgress(state => !state);

      const pdf_data = [];
      setLoadingCount(0);
      setTotalPredictCount(shopInputs.length);
      let count = 0;

      for (const [index, shopInput] of shopInputs.entries()) {
        count = count + 1;
        setLoadingCount(count);

        if (inputType === 'simple' && (shopInput.sub_category === null || shopInput.address === null)) {
          worksheet.getCell(`AW${visibleRow[index] + 1}`).value = '小分類または住所が未入力です。';
          continue;
        }

        try {
          const location = await getGeocode(google, shopInput);

          let foodCompetitor;
          let supermarketCompetitor;
          let homeGoodsStoreCompetitor;
          let drugstoreCompetitor;

          for (const type of ShopTypes) {
            const shopList = await getNearbySearch(google, map, location, type);
            if (type === 'restaurant') {
              foodCompetitor = shopList.map((e) => {
                const pos = {lat: e.lat, lng: e.lng};
                const distance = between(location, pos);
                const competitor = {food_competitor: e.name, food_competitor_distance: distance};
                return competitor;
              });
            } else if (type === 'supermarket') {
              supermarketCompetitor = shopList.map((e) => {
                const pos = {lat: e.lat, lng: e.lng};
                const distance = between(location, pos);
                const competitor = {supermaket_competitor: e.name, supermaket_competitor_distance: distance};
                return competitor;
              });
            } else if (type === 'home_goods_store') {
              homeGoodsStoreCompetitor = shopList.map((e) => {
                const pos = {lat: e.lat, lng: e.lng};
                const distance = between(location, pos);
                const competitor = {homecenter_competitor: e.name, homecenter_competitor_distance: distance};
                return competitor;
              });
            } else {
              drugstoreCompetitor = shopList.map((e) => {
                const pos = {lat: e.lat, lng: e.lng};
                const distance = between(location, pos);
                const competitor = {drug_store_competitor: e.name, drug_store_competitor_distance: distance};
                return competitor;
              });
            }
          }
          const shop = {
            ...shopInput,
            food_competitor_list: foodCompetitor,
            supermaket_competitor_list: supermarketCompetitor,
            homecenter_competitor_list: homeGoodsStoreCompetitor,
            drug_store_competitor_list: drugstoreCompetitor
          }
          console.log(shop);

          const url = process.env.REACT_APP_AI_API + (inputType === 'simple' ? '/predict_loadside_simplified' : '/predict_loadside_full');

          let res = await axios.post(
            url,
            shop,
            {
              ContentType: 'application/json'
            }
          );
          const result = Math.floor(res.data.predictions);

          shop['predictions'] = result;
          shop['male_population_15_5minutes_walk'] = res.data.return_terra_data.male_population_15_5minutes_walk;
          shop['female_population_15_5minutes_walk'] = res.data.return_terra_data.female_population_15_5minutes_walk;
          shop['total_population_15_5minutes_walk'] = res.data.return_terra_data.total_population_15_5minutes_walk;
          shop['total_population_change_from_10_to_15_5minutes_walk'] = res.data.return_terra_data.total_population_change_from_10_to_15_5minutes_walk;
          shop['total_daytime_population_15S_5minutes_walk'] = res.data.return_terra_data.total_daytime_population_15S_5minutes_walk;
          shop['number_of_households_15_5minutes_walk'] = res.data.return_terra_data.number_of_households_15_5minutes_walk;
          shop['number_of_single_family_households_15_5minutes_walk'] = res.data.return_terra_data.number_of_single_family_households_15_5minutes_walk;
          shop['number_of_owner_households_15_5minutes_walk'] = res.data.return_terra_data.number_of_owner_households_15_5minutes_walk;
          shop['number_of_households_apartment_house_15_5minutes_walk'] = res.data.return_terra_data.number_of_households_apartment_house_15_5minutes_walk;
          shop['retail_total_annual_sales_14S_5minutes_walk'] = res.data.return_terra_data.retail_total_annual_sales_14S_5minutes_walk;

          if (inputType === 'simple') {
            worksheet.getCell(`AW${visibleRow[index] + 1}`).value = result;
          } else {
            worksheet.getCell(`BT${visibleRow[index] + 1}`).value = result;
          }

          if (inputType === 'full') {
            const cur_user = JSON.parse(localStorage.getItem('user'));
            const uid = cur_user.uid;
            const html = await axios.post(
              process.env.REACT_APP_AUTH_SERVER + 'pdf/create',
              {
                uid: uid,
                shopData: shop,
              },
              {
                headers: {
                  Authorization: `Bearer ${authTokens}`,
                  ContentType: 'application/json',
                }
              }
            );

            document.getElementById('p1').innerHTML = html.data.p1.toString();
            document.getElementById('p2').innerHTML = html.data.p2.toString();
            await handleCrtPDF(shop['shop_name'], pdf_data);
          }
        } catch(err) {
          console.log(err);
          console.log(err.response);
          const statusCode = err.response.status;
          let errorStatement;
          switch (err.response?.status) {
            case 400:
            case 401:
              setAuthTokens(null);
              setSignedInUser(null);
          }
          if (statusCode === 502 || statusCode === 503) {
            errorStatement = 'システムエラーが発生しました。システム管理者に連絡してください。';
          } else {
            errorStatement = err.response.data.error;
          }

          if (inputType === 'simple') {
            worksheet.getCell(`AW${visibleRow[index] + 1}`).value =  `${errorStatement}(${statusCode})`;
          } else {
            worksheet.getCell(`BT${visibleRow[index] + 1}`).value = `${errorStatement}(${statusCode})`;
          }
        }
      }
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {type: 'application/octet-binary'});
      saveAs(blob, filename);

      if (inputType === 'full') {
        pdf_data.forEach((element, index) => {
          count = count + 1;
          setLoadingCount(count);
          const download_filename = filename.substring(0, filename.lastIndexOf('.')) || filename;
          element.pdf.save(download_filename + '_' + (index + 1) + '_' + element.shop_name + '.pdf');
        });
      }


      setFilename('');
      setInputType('simple');
      setErrOpen(false);
      setErrMsg('');
      setErrOpenExecution(false);
      setErrMsgExecution('');
      setProgress(state => !state);
      onClose();
    };
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        aria-labelledby='form-dialog-title'
        classes={{paper: classes.dialogPaper}}
      >
        <DialogTitle style={{padding: '0px'}}>
          <Grid container style={{background: 'lightgray', padding: '3px 13px'}}>
            <Typography variant='body1' component='div'>
              一括予測
            </Typography>
          </Grid>
          <Grid container style={{display: 'flex', justifyContent: 'flex-end'}}>
            <DialogActions classes={{root: classes.closeButtonPadding}}>
              <Button
                onClick={handleDialogClose}
                variant='contained'
                className={classes.dialogButton}
                classes={{label: classes.dialogButtonString}}
              >
                閉じる
              </Button>
            </DialogActions>
          </Grid>
        </DialogTitle>
        <DialogContent classes={{root: classes.dialogContentRoot}}>
          <Grid container style={{padding: '10px', border: 'solid lightgray 1px'}}>
            <Grid item xs={12}>
              <Table>
                <TableBody>
                  <TableRow className={classes.th1}>
                    <TableCell className={classes.inputGroupTitle}>一括予測</TableCell>
                  </TableRow>
                  <TableRow className={classes.th2}></TableRow>
                </TableBody>
              </Table>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.itemNameCell}>入力タイプ</TableCell>
                    <TableCell className={classes.itemValueCell}>
                      <FormControl component="fieldset" className={classes.formControl} >
                        <RadioGroup row aria-label="predict" name="row-radio-buttons-group">
                          <FormControlLabel
                            value="simple"
                            control={
                              <Radio
                                color='primary'
                                checked={inputType === 'simple'}
                                onChange={handleInputTypeChange}
                                icon={<RadioButtonUncheckedIcon style={{height: '20px'}} />}
                                checkedIcon={<RadioButtonCheckedIcon style={{height: '20px'}} />}
                                classes={{root: classes.radio}}
                              />
                            }
                            label="簡易"
                            classes={{root: classes.formControlLabel, label: classes.radioLabel}}
                          />
                          <FormControlLabel
                            value="full"
                            control={
                              <Radio
                                color='primary'
                                checked={inputType === 'full'}
                                onChange={handleInputTypeChange}
                                icon={<RadioButtonUncheckedIcon style={{height: '20px'}} />}
                                checkedIcon={<RadioButtonCheckedIcon style={{height: '20px'}} />}
                                classes={{root: classes.radio}}
                              />}
                            label="フル"
                            classes={{root: classes.formControlLabel, label: classes.radioLabel}}
                          />
                        </RadioGroup>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.itemNameCell}>ファイル名</TableCell>
                    <TableCell className={classes.itemValueCell}>{filename}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid container style={{padding: '3px 0', display: 'flex', justifyContent: 'flex-end'}}>
              <DialogActions
                classes={{root: classes.closeButtonPadding}}
                style={{paddingRight: '0'}}
              >

                <Button
                  component="label"
                  variant='contained'
                  className={classes.dialogButton}
                  classes={{label: classes.dialogButtonString}}
                >
                  <input
                    accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    className={classes.input}
                    type="file"
                    onChange={handleUploadExcelFile}
                  />
                  EXCEL取込
                </Button>
                <Button
                  component="label"
                  variant='contained'
                  className={classes.dialogButton}
                  classes={{label: classes.dialogButtonString}}
                  onClick={handlePrediction}
                  // disabled={filename === '' || errMsg !== ''}
                >
                  実行
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={progress} classes={{paper: classes.progressDialog}}>
        <DialogTitle style={{background: 'lightgray', padding: '3px 13px'}}>
          <Typography variant='body1' component='div'>
            処理中 ({loadingCount}/{totalpredictCount}件)
          </Typography>
        </DialogTitle>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <Alert open={errOpen} handleClose={handleErrDialogClose} msg={errMsg} okBtnName={'閉じる'} />
      <Alert open={errOpenExecution} handleClose={handleErrDialogExecutionClose} msg={errMsgExecution} okBtnName={'閉じる'} />
    </React.Fragment>
  );
}
